
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import styles from '../styles/burgerMenu.module.css'
import home2 from '../Images/home2.svg'
import play from '../Images/play.svg'
import catalog from '../Images/catalog.svg'
import heart from '../Images/heart.svg'
import createCourse from '../Images/createCourse.svg'
import freelanceCatalog from '../Images/freelanceCatalog.svg'

import { useNavigate } from "react-router-dom";
import { CATALOG_ROUTE, FAVORITE_COURSES, FREELANCE_CATALOG, LANDING_ROUTE, LOGIN_PAGE, SETTINGS_ROUTE, SIGNUP_PAGE } from "../utils";
import { Context } from "..";

function BurgerMenu() {

    const { store } = useContext(Context)
    const navigate = useNavigate()


    const navigateHandler = (path) => {
        if (path === 'landing') {
            navigate(LANDING_ROUTE)
        }
        if (path === 'catalog') {
            navigate(CATALOG_ROUTE)
        }
        if (path === 'favorite') {
            if (store.isAuth) {
                navigate(FAVORITE_COURSES + '/' + store.user.id)
            } else {
                navigate(LOGIN_PAGE)
            }
        }
        if (path === 'create') {
            if (store.isAuth) {
                navigate(SETTINGS_ROUTE + '/' + store.user.id, { state: { from: '/burgerMenu' } })
            } else {
                navigate(LOGIN_PAGE)
            }
        }
        if (path === 'freelance') {
            navigate(FREELANCE_CATALOG)
        }
    }
    return (
        <>
            <div className={styles.burgerContainer}>
                <div className={styles.navButtons}>
                    <button onClick={() => navigateHandler('landing')}>
                        <img src={home2} alt="" />
                        <p>Главная</p>
                    </button>
                    {/* <button onClick={() => navigateHandler('courses')}>
                        <img src={play} alt="" />
                        <p>Курсы</p>
                    </button> */}
                    <button onClick={() => navigateHandler('catalog')}>
                        <img src={catalog} alt="" />
                        <p>Каталог</p>
                    </button>
                    <button onClick={() => navigateHandler('favorite')}>
                        <img src={heart} alt="" />
                        <p>Избранное</p>
                    </button>
                    <button onClick={() => navigateHandler('create')}>
                        <img src={createCourse} alt="" />
                        <p>Создать</p>
                    </button>
                    <button onClick={() => navigateHandler('freelance')}>
                        <img src={freelanceCatalog} alt="" />
                        <p>Фриланс</p>
                    </button>
                </div>
            </div>
        </>
    )
}

export default observer(BurgerMenu)