import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from '../styles/editLesson.module.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import { Context } from "..";
import CoursesService from "../service/CoursesService";

function TextEditor({ chapter, onTextHandler, id }) {
    const [textContent, setTextContent] = useState(chapter)

    const quillRef = useRef(null);
    const { courseStore } = useContext(Context)

    useEffect(() => {
        onTextHandler(textContent)
    }, [textContent])

    useEffect(() => {
        getChapter()
    }, [])

    async function getChapter() {
        try {
            await courseStore.fetchChapter(id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                const savedRange = range ? range.index : 0;
                const formData = new FormData();
                formData.append('img', file);
                formData.append('pos', savedRange);
                formData.append('chapter_id', id);
                try {
                    const response = await CoursesService.uploadLessonImage(formData)
                    const chaptData = response.data.chapter.imgs
                    const chaptImg = JSON.parse(chaptData)[0].fileName
                    const imgPos = JSON.parse(chaptData)[0].pos

                    if (quillRef.current) {
                        const range = quillRef.current.getEditor().getSelection(true);
                        quillRef.current.getEditor().insertEmbed(imgPos, 'image', process.env.REACT_APP_API_URL + chaptImg);
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                    alert("Ошибка загрузки изображения. Пожалуйста, попробуйте еще раз.");
                }
            }
        };
    };


    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link', 'image', 'code-block'],
                ['clean']
            ],
            handlers: {
                'image': handleImageUpload
            }
        }
    }), [])

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'align',
        'link', 'image', 'code-block'
    ];

    const handleChangeText = (value) => {
        changeInfo('description', value, chapter.number);
    };

    const changeInfo = (key, value, number) => {
        setTextContent({ ...chapter, [key]: value })

    }

    return (
        <>
            <div className={styles.textEditor}>
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={textContent.description}
                    onChange={handleChangeText}
                    formats={formats}
                    modules={modules}

                />
            </div>
        </>
    )
}

export default observer(TextEditor)