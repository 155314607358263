import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from '../styles/editTrainer.module.css'
import { observer } from "mobx-react-lite";
import CodeMirror, { lineNumberMarkers, lineNumbers } from "@uiw/react-codemirror";
import { aura } from "@uiw/codemirror-themes-all";
import { langs } from "@uiw/codemirror-extensions-langs";
import { Context } from "..";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import CoursesService from "../service/CoursesService";

function LessonCodeEditor({ chapter, onCodeHandler, id }) {
    const [code, setCode] = useState('');
    const [text, setText] = useState("");
    const quillRef = useRef(null);
    const { courseStore } = useContext(Context)
    const [codeContent, setCodeContent] = useState(chapter)

    useEffect(() => {
        setText(chapter.name)
        setCode(chapter.description)
    }, [])

    useEffect(() => {
        getChapter()
    }, [])

    useEffect(() => {
        changeInfo('description', code, chapter.number)

    }, [code])

    useEffect(() => {
        changeInfo('name', text, chapter.number)

    }, [text])

    useEffect(() => {
        onCodeHandler(codeContent)
    }, [codeContent])

    const handleCodeChange = (newCode) => {
        setCode(newCode);
    };

    const changeInfo = (key, value, number) => {
        setCodeContent({ ...codeContent, [key]: value })

    }

    const handleChangeCodeText = (textt) => {
        setText(textt)
    };

    async function getChapter() {
        try {
            await courseStore.fetchChapter(id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                const savedRange = range ? range.index : 0;
                const formData = new FormData();
                formData.append('img', file);
                formData.append('pos', savedRange);
                formData.append('chapter_id', id);
                try {
                    const response = await CoursesService.uploadLessonImage(formData)
                    const chaptData = response.data.chapter.imgs
                    const chaptImg = JSON.parse(chaptData)[0].fileName
                    const imgPos = JSON.parse(chaptData)[0].pos

                    if (quillRef.current) {
                        const range = quillRef.current.getEditor().getSelection(true);
                        quillRef.current.getEditor().insertEmbed(imgPos, 'image', process.env.REACT_APP_API_URL + chaptImg);
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                    alert("Ошибка загрузки изображения. Пожалуйста, попробуйте еще раз.");
                }
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'code-block'],
                ['clean']
            ],
            handlers: {
                'image': handleImageUpload
            }
        }
    }), [])

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'code-block'
    ];

    return (
        <>

            <div className={styles.textTest}>
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={text}
                    placeholder="Введите содержание урока"
                    onChange={handleChangeCodeText}
                    formats={formats}
                    modules={modules}
                />
            </div>
            <div className={styles.lessonCode}>
                <CodeMirror
                    value={code}
                    theme={aura}
                    width="100%"
                    minHeight='500px'
                    height='auto'
                    extensions={[langs.javascript()]}
                    placeholder={'// Напишите шаблон программы'}
                    basicSetup={{
                        highlightActiveLineGutter: true,
                        highlightSpecialChars: true,
                        history: true,
                        drawSelection: true,
                        dropCursor: true,
                        allowMultipleSelections: true,
                        indentOnInput: true,
                        syntaxHighlighting: true,
                        bracketMatching: true,
                        closeBrackets: true,
                        autocompletion: false,
                        rectangularSelection: true,
                        crosshairCursor: true,
                        highlightActiveLine: true,
                        highlightSelectionMatches: true,
                        closeBracketsKeymap: true,
                        defaultKeymap: true,
                        searchKeymap: true,
                        tabSize: 4,
                    }}
                    onChange={handleCodeChange}
                />

            </div>
        </>
    )
}




export default observer(LessonCodeEditor)