
import { observer } from "mobx-react-lite";
import React from "react";
import styles from '../styles/policy.module.css'
import DocumentTitle from "react-document-title";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Policy() {
    return (
        <>
            <DocumentTitle title="Политика конфиденциальности">
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={`${styles.content} `}>
                        <div className={styles.zagolovok}>
                            Политика обработки персональных данных
                        </div>
                        <div className={`${styles.policy} ${styles.glass}`}>
                            <div className={styles.mainblock}>
                                <div className={styles.orgInfo}>
                                    <p>
                                        Утверждена
                                    </p>
                                    <p>
                                        Приказом Генерального директора
                                    </p>
                                    <p>
                                        ООО «ТЕТА ИНТЕРАКТИВ»
                                    </p>
                                    <p>
                                        г. Томск
                                    </p>
                                    <p>
                                        Приказ № 01/07-ПО от «10» июля 2024 г.
                                    </p>
                                    <p>
                                        Введена в действие с «10» июля 2024 г.
                                    </p>
                                </div>
                                <div className={styles.maininfo}>
                                    <p className={styles.zagolovok1}><b>1. Общие положения</b></p>
                                    <p className={styles.obsheeinfo}>
                                        <p><b>1.1.</b> Настоящий документ определяет политику ООО «ТЕТА ИНТЕРАКТИВ» в отношении обработки персональных данных субъектов персональных данных, использующих сайт https://theta-learn.ru.</p>
                                        <p>
                                            Настоящий документ разработан во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) в целях обеспечения защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну. ООО «ТЕТА ИНТЕРАКТИВ» (634062, г. Томск, Иркутский тракт, д. 128А, кв. 78, ИНН 7000010562, ОГРН 1237000008002) (далее — Администрация) является оператором персональных данных.
                                        </p>
                                        <p>
                                            Текст Политики доступен в сети Интернет по адресу https://theta-learn.ru/privacypolicy. В случае несогласия с условиями Политики Пользователь должен немедленно прекратить использование Сайта.
                                        </p>
                                        <p>
                                            <b>1.2.</b>    Основные понятия:
                                        </p>
                                        <p>
                                            <b>Конфиденциальность персональных данных</b> — обязательное для выполнения Администрацией или иным лицом, получившим доступ к персональным данным, требование не допускать раскрытия персональных данных третьим лицам, и их распространение без Согласия субъекта персональных данных или наличия иного законного основания.
                                        </p>
                                        <p>
                                            <b>Личный кабинет</b> — персональный раздел Сайта и дополнительные функциональные возможности Сайта, к которым Пользователь получает доступ после прохождения регистрации и/или авторизации на Сайте. Личный кабинет предназначен для хранения персональных данных Пользователя, просмотра и управления доступными функциональными возможностями Сайта и соответствующими условиями использования Сайта, управления сделками, совершаемыми посредством Сайта.
                                        </p>
                                        <p>
                                            <b>Обработка персональных данных</b> — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                                        </p>
                                        <p>
                                            <b>Оператор персональных данных</b> — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющее цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. В рамках положений Политики ООО «Интерактивные обучающие технологии» выступает Оператором персональных данных.
                                        </p>
                                        <p>
                                            <b>Персональные данные</b> — любая информация, относящаяся к прямо или косвенно определенному или определяемому Пользователю.
                                        </p>
                                        <p>
                                            <b>Пользователь</b> — субъект персональных данных.
                                        </p>
                                        <p>
                                            <b>Сайт</b> — принадлежащая Администрации совокупность автоматизированных информационных систем и информации вне зависимости от формы ее представления, доступных в сети Интернет по сетевым адресам в следующих доменах (включая поддомены): https://theta-learn.ru.
                                        </p>
                                        <p>
                                            <b>Социальная сеть</b> — сайт в информационно-телекоммуникационной сети Интернет, принадлежащий третьим Лицам, предназначенный для дистанционной коммуникации людей.
                                        </p>
                                        <p>
                                            <b>Способ платежа</b> — информация о банке, платежной системе, средстве платежа, применяемых Пользователем для оплаты Сервиса.
                                        </p>
                                        <p>
                                            <b>Субъект персональных данных</b> — физическое лицо, которое прямо или косвенно определено с помощью персональных данных, в отношении которого осуществляется обработка персональных данных. В рамках настоящей Политики к субъектам персональных данных относятся физические лица, действующие от собственного имени, а также физические лица-представители юридических лиц, уполномоченные действовать от имени таких юридических лиц на основании устава, доверенности, трудового договора, гражданско-правового договора или по другим основаниям.
                                        </p>
                                        1.3. Основные права и обязанности Администрации.


                                        <p> 1.3.1. Администрация имеет право:</p>
                                        <p className={styles.pp}>
                                            <p>
                                                - самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним нормативными правовыми актами;
                                            </p>
                                            <p>
                                                - поручить обработку персональных данных другому лицу с согласия Пользователя, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Администрации, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом о персональных данных;
                                            </p>
                                            <p>
                                                - в случае отзыва Пользователем согласия на обработку персональных данных Администрация вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в Законе о персональных данных.
                                            </p>
                                        </p>
                                        <p>
                                            1.3.2. Администрация обязана:
                                        </p>
                                        <p className={styles.pp}>
                                            <p>
                                                - организовывать обработку персональных данных в соответствии с требованиями Закона о персональных данных;
                                            </p>
                                            <p>
                                                - отвечать на обращения и запросы Пользователя и его законных представителей в соответствии с требованиями Закона о персональных данных и в порядке, предусмотренном Политикой;
                                            </p>
                                            <p>
                                                - сообщать в уполномоченный орган по защите прав субъектов персональных данных по запросу этого органа необходимую информацию в установленные сроки.
                                            </p>
                                        </p>
                                        <p>
                                            1.3.3. Основные права Пользователя. Пользователь имеет право:
                                        </p>
                                        <p className={styles.pp}>
                                            <p>
                                                - по своему усмотрению, своей волей и в своём интересе предоставлять Администрации персональные данные для их обработки на условиях, указанных в настоящей Политике;
                                            </p>
                                            <p>
                                                - самостоятельно предоставлять, изменять и удалять персональные данные в Личном кабинете при условии, что такие изменения и исправления содержат актуальную и достоверную информацию;
                                            </p>
                                            <p>
                                                - обращаться к Администрации с требованиями, в том числе об уточнении его персональных данных, об уничтожении персональных данных, отзыве согласия на обработку, блокировке персональных данных, в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, о получении информации, касающейся обработки его персональных данных. Требование предъявляется в порядке, предусмотренном в п. 6.2. Политики.
                                            </p>
                                            <p>
                                                - самостоятельно удалять Личный кабинет или обращаться с требованием к Администрации об удалении Личного кабинета силами Администрации в порядке, предусмотренном в п. 6.2. Политики;
                                            </p>
                                            <p>
                                                - самостоятельно устанавливать режим публичного доступа (режим видимости) части персональных данных, отменять такой режим;
                                            </p>
                                            <p>
                                                - принимать предусмотренные законом меры по защите своих прав;
                                            </p>
                                            <p>
                                                - получать информацию, касающуюся обработки его персональных данных способами, предусмотренными п. Политики.
                                            </p>
                                        </p>
                                    </p>
                                </div>
                                <div className={styles.maininfo}>
                                    <p className={styles.zagolovok1}><b>2. Цели сбора персональных данных</b></p>
                                    <p className={styles.obsheeinfo}>

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>
        </>
    )
}

export default observer(Policy)