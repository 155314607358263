import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import DocumentTitle from "react-document-title";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from '../styles/authorization.module.css'
import mail2 from '../Images/mail2.svg'
import passwordIcon from '../Images/passwordIcon.svg'
import { useNavigate } from "react-router-dom";
import { CATALOG_ROUTE } from "../utils";
import { Context } from "..";
import alert from '../Images/alert.svg'

function LoginIn() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState(true)
    const navigate = useNavigate()
    const { store } = useContext(Context)
    const [forget, setForget] = useState('none')
    const [sendCode, setSendCode] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [NPErrorText, setNPErrorText] = useState('Обязательное поле')
    const [newPasswordError, setNewPasswordError] = useState(true)
    const [retryNPErrorText, setRetryNPErrorText] = useState('Обязательное поле')
    const [retryNewPasswordError, setRetryNewPasswordError] = useState(true)
    const [changeCode, setChangeCode] = useState('')
    const [changeCodeError, setChangeCodeError] = useState(true)
    const [changeCodeTextError, setChangeCodeTextError] = useState('Обязательное поле')
    const [textEmailError, setTextEmailError] = useState('Обязательное поле')
    const [passwordError, setPasswordError] = useState('Обязательное поле')

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setTextEmailError('Некорректный email')
            setEmailError(true)

        } else {
            setTextEmailError('')
            setEmailError(false)
        }

    }

    const newPasswordHandler = (e) => {
        setNewPassword(e.target.value)

        if (e.target.value.length < 6 || e.target.value.length > 32) {
            setNewPasswordError(true)
            setNPErrorText('Пароль должен быть больше 6 знаков')
        }
        if (e.target.value === '') {
            setNewPasswordError(false)
            setNPErrorText('')
        }
        if (e.target.value.length >= 6 && e.target.value.length < 32) {
            setNewPasswordError(false)
            setNPErrorText('')
        }
        if (e.target.value.length === 0) {
            setNewPasswordError(true)
            setNPErrorText('Обязательное поле')
        }
    }

    const retryNewPasswordHandler = (e) => {
        if (e.target.value !== newPassword) {
            setRetryNewPasswordError(true)
            setRetryNPErrorText('Пароли не совпадают')
        } else {
            setRetryNewPasswordError(false)
            setRetryNPErrorText('')
        }
    }

    const sendCodePasswordHandler = () => {
        store.sendChangePasswordCode(email)
        setSendCode(true)
    }

    const changeCodeHandler = (e) => {
        setChangeCode(e.target.value)
        if (e.target.value != store.user.changeCode) {
            setChangeCodeError(true)
            setChangeCodeTextError('Код неверный')
        }
        if (e.target.value == store.user.changeCode) {
            setChangeCodeError(false)
            setChangeCodeTextError('')
        }
        if (e.target.value.length === 0) {
            setChangeCodeError(true)
            setChangeCodeTextError('Обязательное поле')
        }
    }

    const completeChangeHandler = () => {
        if (forget === 'password') {
            store.changePassword(store.user.email, changeCode, newPassword)
            setForget('none')
            setSendCode(false)
        }
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        if (e.target.value.length < 6) {
            setPasswordError('Пароль должен быть больше 6 знаков')
            if (e.target.value === '') {
                setPasswordError('Обязательное поле')
            }
        } else {
            setPasswordError('')
        }
    }

    async function loginHandler() {
        await store.login(email, password)
        navigate(CATALOG_ROUTE)
    }

    const forgetHandler = (val) => {
        setForget(val)
    }


    return (
        <DocumentTitle title="Вход">
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.authBlock}>
                            {
                                forget !== 'password' &&
                                <>
                                    <p className={styles.zagolovok}>Вход</p>
                                    <div className={styles.inputs}>
                                        <div>
                                            {
                                                emailError &&
                                                <div className={styles.error}>
                                                    {textEmailError}
                                                    <img src={alert} alt="" />
                                                </div>
                                            }
                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={mail2} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="email"
                                                        placeholder="Почта"
                                                        onChange={(e) => emailHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                passwordError !== '' &&
                                                <div className={styles.error}>
                                                    {passwordError}
                                                    <img src={alert} alt="" />
                                                </div>
                                            }

                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={passwordIcon} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="password"
                                                        placeholder="Пароль"
                                                        onChange={(e) => passwordHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p className={styles.forgotPassword} onClick={() => forgetHandler('password')}>Не помню пароль</p>
                                    </div>
                                </>
                            }
                            {
                                (forget === 'password') &&
                                <div className={styles.changePass}>
                                    {
                                        (forget === 'password' && !sendCode) &&
                                        <div className={styles.changeBlock}>
                                            <p>Изменить пароль</p>
                                            <div>
                                                <div className={styles.changeInputs}>
                                                    <div>
                                                        {
                                                            emailError &&
                                                            <p className={styles.error}>
                                                                {textEmailError}
                                                                <img src={alert} alt="" />
                                                            </p>
                                                        }
                                                        <div className={styles.email}>
                                                            <div className={styles.imgg}>
                                                                <img src={mail2} alt="" />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className={styles.inputt}
                                                                    type="email"
                                                                    placeholder="Почта"
                                                                    onChange={(e) => emailHandler(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            newPasswordError &&
                                                            <p>
                                                                {NPErrorText}
                                                                <img src={alert} alt="" />
                                                            </p>
                                                        }
                                                        <div className={styles.email}>
                                                            <div className={styles.imgg}>
                                                                <img src={passwordIcon} alt="" />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className={styles.inputt}
                                                                    type="password"
                                                                    placeholder="Новый пароль"
                                                                    onChange={(e) => newPasswordHandler(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {
                                                            retryNewPasswordError &&
                                                            <p>
                                                                {retryNPErrorText}
                                                                <img src={alert} alt="" />
                                                            </p>
                                                        }
                                                        <div className={styles.email}>
                                                            <div className={styles.imgg}>
                                                                <img src={passwordIcon} alt="" />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className={styles.inputt}
                                                                    type="password"
                                                                    placeholder="Повторите пароль"
                                                                    onChange={(e) => retryNewPasswordHandler(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className={(!retryNewPasswordError && !newPasswordError) ? `${styles.saveChangeButton}` : `${styles.saveChangeButtonDisabled}`}
                                                disabled={(!retryNewPasswordError && !newPasswordError) ? false : true}
                                                onClick={sendCodePasswordHandler}
                                            >
                                                Сохранить изменения
                                            </button>
                                        </div>
                                    }
                                    {
                                        sendCode &&
                                        <div className={styles.changeBlock}>
                                            <p>Сообщение с кодом отправленно на вашу почту</p>
                                            <div>
                                                <div className={styles.changeInputs}>
                                                    <div>
                                                        {
                                                            changeCodeError &&
                                                            <p>
                                                                {changeCodeTextError}
                                                                <img src={alert} alt="" />
                                                            </p>
                                                        }
                                                        <div className={styles.email}>
                                                            <div className={styles.imgg}>
                                                                <img src={mail2} alt="" />
                                                            </div>
                                                            <div>
                                                                <input
                                                                    className={styles.inputt}
                                                                    type="email"
                                                                    placeholder="Код"
                                                                    onChange={(e) => changeCodeHandler(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className={!changeCodeError ? `${styles.saveChangeButton}` : `${styles.saveChangeButtonDisabled}`}
                                                disabled={!changeCodeError ? false : true}
                                                onClick={completeChangeHandler}
                                            >
                                                Готово
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                            {
                                forget !== 'password' &&
                                <button
                                    className={(passwordError === '' && !emailError) ? styles.login : styles.noLogin}
                                    onClick={loginHandler}
                                    disabled={(passwordError === '' && !emailError) ? false : true}

                                >
                                    Войти
                                </button>
                            }
                        </div>

                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}
export default observer(LoginIn)