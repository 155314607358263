import React, { useContext } from "react";
import styles from '../styles/landing.module.css'
import { Context } from "..";
import { observer } from "mobx-react-lite";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { CATALOG_ROUTE, CREATE_ROUTE, FREELANCE_CATALOG, LOGIN_PAGE, PROFILE_ROUTE, SETTINGS_ROUTE, TEST_PAGE, TRAINER_PAGE } from "../utils";
import DocumentTitle from "react-document-title";
import fsi from '../Images/fsi.svg'
import BurgerMenu from "../components/BurgerMenu";
import learn from '../Images/learn.svg'
import computer from '../Images/computer.svg'
import money from '../Images/money.svg'
import trainers from '../Images/trainers.svg'

function Landing() {
    const navigate = useNavigate()
    const { store } = useContext(Context)

    const navigateHandler = (path) => {
        if (path === 'catalog') {
            navigate(CATALOG_ROUTE)
        }
        if (path === 'create') {
            if (store.isAuth) {
                navigate(SETTINGS_ROUTE + '/' + store.user.id, { state: { from: '/burgerMenu' } })
            } else {
                navigate(LOGIN_PAGE)
            }
        }
        if (path === 'freelance') {
            navigate(FREELANCE_CATALOG)
        }
    }



    return (
        <DocumentTitle title='Theta'>
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>

                    <div className={styles.content}>
                        <BurgerMenu />

                        <div className={styles.nameAndSupport}>
                            <div className={styles.name}>
                                <p className={styles.theta} >Theta</p>
                                <p className={styles.aboutUs}>Мы - стартап команда, нацеленная на создание качественного образовательного материала. Мы создаем экосистему в которой каждый сможет найти то, что ему нужно</p>
                            </div>
                            <div className={styles.support}>
                                <p>ПРИ ПОДДЕРЖКЕ ФСИ</p>
                                <img src={fsi} alt="" />
                            </div>
                        </div>

                        <div className={styles.aboutTheta}>
                            <hr className={styles.line} />
                            <div onClick={() => navigateHandler('catalog')}>
                                <img src={learn} alt="" />
                                <div>
                                    <p className={styles.opportName}>Учитесь</p>
                                    <p className={styles.opportDescription}>
                                        У нас вы найдете большое количество образовательного материала разной степени сложности. Мы делаем упор на практику, а также актуальность контента.
                                    </p>
                                </div>

                            </div>
                            <hr className={styles.line} />

                            <div>
                                <img src={computer} alt="" />
                                <div onClick={() => navigateHandler('create')}>
                                    <p className={styles.opportName}>Делитесь опытом и навыками</p>
                                    <p className={styles.opportDescription}>
                                        Если у вас есть, о чем вы можете поделиться с пользователями, воспользуйтесь нашим конструктором и создайте свой собственный курс!
                                    </p>
                                </div>
                            </div>
                            <hr className={styles.line} />
                            <div>
                                <img src={money} alt="" />
                                <div>
                                    <p className={styles.opportName} onClick={() => navigateHandler('freelance')}>Фриланс</p>
                                    <p className={styles.opportDescription}>
                                        Вы можете размещать свои заявки на нашей бирже фриланса для выполнения своих задач.
                                    </p>
                                </div>
                            </div>
                            <hr className={styles.line} />
                            {/* <div>
                                <img src={trainers} alt="" />
                                <div>
                                    <p className={styles.opportName} onClick={() => navigateHandler('trainers')}>Решайте задачи</p>
                                    <p className={styles.opportDescription}>
                                        Переходите к нашим тренажерем и прокачивайте свои навыки в программирование.
                                    </p>
                                </div>
                            </div>
                            <hr className={styles.line} /> */}
                        </div>

                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle >
    )
}

function InfoCard() {

    return (
        <>
            <div className={styles.cardContainer}>
                <div className={styles.cardName}>
                    <p>Возможности</p>
                </div>
                <div className={styles.cardInfo}>
                    <div className={`${styles.mainCardInfo} ${styles.glass}`}>
                        <p>
                            Учиться
                        </p>
                        <div>
                            <p>
                                На Theta вы можете как обучаться, так и обучать. Познавайте новое или делитесь опытом.
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.dopCardInfo} `}>
                        <div className={styles.glass}>
                            <p>Обучать</p>
                        </div>
                        <div className={styles.glass}>
                            <p>Практика</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function InfoCardMobile() {
    return (
        <>
            <div className={styles.cardContainerMobile}>
                <div className={styles.cardNameMobile}>
                    <p>Возможности</p>
                </div>
                <div className={styles.cardInfoMobile}>
                    <div className={`${styles.glass2}`}>
                        <p>Учиться</p>
                    </div>
                    <div className={`${styles.glass2}`}>
                        <p>Преподавать</p>
                    </div>
                    <div className={`${styles.glass2}`}>
                        <p>Практиковаться</p>
                    </div>

                </div>

            </div>
        </>
    )
}




export default observer(Landing)