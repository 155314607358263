import { makeAutoObservable } from "mobx";
import { IUser } from "../models/IUser";
import axios from 'axios'
import { AuthResponse } from "../models/response/AuthResponse";
import { APi_URL } from "../http";
import AuthService from "../service/AuthService";
import { ITest } from "../models/ITest";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default class Store {
    user = {} as IUser
    isLoading = false
    isAuth = false
    test = {} as ITest
    constructor() {
        makeAutoObservable(this)
    }
    setAuth(bool: boolean) {
        this.isAuth = bool
    }

    setUser(user: IUser) {
        this.user = user
    }

    setAnswer(test: ITest) {
        this.test = test
    }

    setLoading(bool: boolean) {
        this.isLoading = bool
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, password)
            localStorage.setItem('token', response.data.accessToken)
            localStorage.setItem('userId', response.data.user.id)
            this.setAuth(true)
            this.setUser(response.data.user)
            toast.success('Есть контакт!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }


    async saveData(formdata: any) {
        try {
            const response = await AuthService.saveData(formdata)
            this.setUser(response.data.user)
            toast.success('Данные сохранены!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async registration(email: string, password: string, username: string) {
        try {
            const response = await AuthService.registration(email, password, username)
            localStorage.setItem('token', response.data.accessToken)
            this.setAuth(true)
            this.setUser(response.data.user)
            toast.success('Есть контакт!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout()
            localStorage.removeItem('token')
            this.setAuth(false)
            this.setUser({} as IUser)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async sendChangePasswordCode(email: string, newEmail: string) {
        try {
            const response = await AuthService.sendChangePasswordCode(email, newEmail)
            this.setUser(response.data.user)
            toast.success('Код отправлен на вашу почту!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async changePassword(email: string, code: BigInt, newPassword: string) {
        try {
            const response = await AuthService.changePassword(email, code, newPassword)
            this.setUser(response.data.user)
            toast.success('Пароль изменен!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async changeEmail(oldEmail: string, newEmail: string, code: BigInt) {
        try {
            const response = await AuthService.changeEmail(oldEmail, newEmail, code)
            this.setUser(response.data.user)
            toast.success('Почта изменена!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async checkAuth() {
        this.setLoading(true)
        try {
            const response = await axios.get<AuthResponse>(`${APi_URL}/user/refresh`, { withCredentials: true })
            localStorage.setItem('token', response.data.accessToken)
            localStorage.setItem('userId', response.data.user.id)
            this.setAuth(true)
            this.setUser(response.data.user)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false)
        }
    }

    async sendToAI(answer: any) {
        try {
            this.setLoading(true)
            const msg = [{ "role": "user", "content": `${answer}, что делать, опиши кратко в 400 символов` }]
            const mdel = "deepseek-chat"
            const response = await AuthService.sendAiText(mdel, msg)
            this.setAnswer(response.data)
        } catch (e) {
            console.log(e.response?.data?.message)
        } finally {
            this.setLoading(false)
        }
    }

    async saveTestResult(answer: string, id: string) {
        try {
            const response = await AuthService.saveTestResult(answer, id)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }
} 