import Catalog from "./pages/Catalog"
import CreateCoursesBlock from "./pages/CreateCoursesBlock"
import FreeCourses from "./pages/FreeCourses"
import Landing from "./pages/Landing"
import ProfileSettings from "./pages/ProfileSettings"
import { AUTHORIZATION_PAGE, CATALOG_ROUTE, CATALOG_TRAINERS, COURSES_CONTENT, COURSE_CARD, COURSE_LESSONS, COURSE_MANAGMENT, COURSE_PAGE, CREATE_ROUTE, CREATE_TRAINER, EDITCOURSE_PAGE, EDIT_LESSON, EDIT_OFFER, EDIT_TRAINER, FAVORITE_COURSES, FREELANCE_CATALOG, FREE_COURSE, LANDING_ROUTE, LEARN, LEARN_MAIN_PAGE, LESSON_PAGE, LOGIN_PAGE, MODULE_PAGE, MY_TRAINERS, PERSONAL_PAGE, POLICY_PAGE, PROFILE_ROUTE, SETTINGS_ROUTE, SIGNUP_PAGE, SOLVE_TRAINER, TRAINER_PAGE } from "./utils"
import CourseLessons from "./pages/CourseLessons"
import ModulePage from "./pages/ModulePage"
import EditCoursePage from "./pages/EditCoursePage"
import CourseManagment from "./pages/CourseManagment"
import TrainerPage from "./pages/TrainerPage"
import CreateTrainer from "./pages/CreateTrainer"
import EditTrainer from "./pages/EditTrainer"
import MyTrainers from "./pages/MyTrainers"
import CatalogTrainers from "./pages/CatalogTrainers"
import SolveTrainer from "./pages/SolveTrainer"
import PolicyPage from "./pages/PolicyPage"
import CourseContentPage from "./pages/CourseContentPage"
import SignUp from "./pages/SignUp"
import LoginIn from "./pages/LoginIn"
import FavoritesCourses from "./pages/FavoritesCourses"
import MainCoursePage from "./pages/MainCoursePage"
import MainLearnCoursePage from "./pages/MainLearnCoursePage"
import EditLesson from "./pages/EditLesson"
import FreelanceCatalog from "./pages/FreelanceCatalog"
import EditOffer from "./pages/EditOffer"

export const authRoutes = [
    {
        path: COURSE_MANAGMENT + '/:id',
        Component: CourseManagment
    },
    {
        path: SETTINGS_ROUTE + '/:id',
        Component: ProfileSettings
    },
    {
        path: FREE_COURSE + '/:id',
        Component: FreeCourses
    },
    {
        path: COURSE_LESSONS,
        Component: CourseLessons
    },
    {
        path: COURSES_CONTENT + '/:id' + MODULE_PAGE + '/:id',
        Component: ModulePage
    },
    {
        path: EDITCOURSE_PAGE + '/:id',
        Component: EditCoursePage
    },
    {
        path: CREATE_TRAINER + '/:id',
        Component: CreateTrainer
    },
    {
        path: EDIT_TRAINER + '/:id',
        Component: EditTrainer
    },
    {
        path: MY_TRAINERS + '/:id',
        Component: MyTrainers
    },
    {
        path: EDIT_LESSON + '/:id',
        Component: EditLesson
    },
    {
        path: SOLVE_TRAINER + '/:id',
        Component: SolveTrainer
    },
    {
        path: COURSE_CARD + '/:id' + LEARN + LESSON_PAGE + '/:id',
        Component: CourseContentPage
    },
    {
        path: FAVORITE_COURSES + '/:id',
        Component: FavoritesCourses
    },
    {
        path: LEARN_MAIN_PAGE + '/:id',
        Component: MainLearnCoursePage
    },
    {
        path: EDIT_OFFER + '/:id',
        Component: EditOffer
    },
]

export const publicRoutes = [
    {
        path: LANDING_ROUTE,
        Component: Landing
    },
    {
        path: CATALOG_ROUTE,
        Component: Catalog
    },
    {
        path: CREATE_ROUTE,
        Component: CreateCoursesBlock
    },
    {
        path: SIGNUP_PAGE,
        Component: SignUp
    },
    {
        path: LOGIN_PAGE,
        Component: LoginIn
    },
    {
        path: TRAINER_PAGE,
        Component: TrainerPage
    },
    {
        path: CATALOG_TRAINERS,
        Component: CatalogTrainers
    },
    {
        path: POLICY_PAGE,
        Component: PolicyPage
    },
    {
        path: COURSE_CARD + '/:id',
        Component: MainCoursePage
    },
    {
        path: FREELANCE_CATALOG,
        Component: FreelanceCatalog
    }
]