import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import DocumentTitle from "react-document-title";
import styles from '../styles/authorization.module.css'
import Header from "../components/Header";
import Footer from "../components/Footer";
import mail2 from '../Images/mail2.svg'
import passwordIcon from '../Images/passwordIcon.svg'
import { useNavigate } from "react-router-dom";
import { Context } from "..";
import { CATALOG_ROUTE } from "../utils";
import alert from '../Images/alert.svg'

function SignUp() {
    const { store } = useContext(Context)
    const navigate = useNavigate()
    const [showAuth, setShowAuth] = useState('vhod')
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('Обязательное поле')
    const [password, setPassword] = useState('')
    const [passwordRetry, setPasswordRetry] = useState('')
    const [username, setUsername] = useState('')
    const [passwordError, setPasswordError] = useState('Обязательное поле')
    const [retryPasswordError, setRetryPasswordError] = useState('Обязательное поле')
    const [signUp, setSignUp] = useState(false)
    const signUpHandler = async () => {
        if (password === passwordRetry) {
            await store.registration(email, password, username)
            navigate(CATALOG_ROUTE)
        }
    }

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('Некорректный email')
        } else {
            setEmailError('')
        }
        checkForErrors()
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        if (e.target.value.length < 6) {
            setPasswordError('Пароль должен быть больше 6 знаков')
            if (e.target.value === '') {
                setPasswordError('Обязательное поле')
            }
        } else {
            setPasswordError('')
        }
        checkForErrors()
    }

    const retryPasswordHandler = (e) => {
        setPasswordRetry(e.target.value)
        if (e.target.value !== password) {
            setRetryPasswordError('Пароли не совпадают')
        } else {
            setRetryPasswordError('')
        }
        checkForErrors()
    }

    const usernameHandler = (e) => {
        setUsername(e.target.value)

    }

    const checkForErrors = () => {
        if (retryPasswordError === '' && passwordError === '' && emailError === '') {
            setSignUp(true)
        }
    }


    return (
        <DocumentTitle title="Регистрация">
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <div className={styles.authBlock}>
                            <p className={styles.zagolovok}>Регистрация</p>
                            <div className={styles.inputs}>
                                <div className={styles.name}>
                                    <div>
                                        <input
                                            className={styles.inputtUs}
                                            type="email"
                                            placeholder="Юзернейм"
                                            onChange={(e) => usernameHandler(e)}
                                            value={username}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {
                                        emailError !== '' &&
                                        <div className={styles.error}>
                                            {emailError}
                                            <img src={alert} alt="" />
                                        </div>
                                    }
                                    <div className={styles.email}>
                                        <div className={styles.imgg}>
                                            <img src={mail2} alt="" />
                                        </div>
                                        <div>
                                            <input
                                                className={styles.inputt}
                                                type="email"
                                                placeholder="Почта"
                                                onChange={(e) => emailHandler(e)}
                                                value={email}

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        passwordError !== '' &&
                                        <div className={styles.error}>
                                            {passwordError}
                                            <img src={alert} alt="" />
                                        </div>
                                    }
                                    <div className={styles.email}>
                                        <div className={styles.imgg}>
                                            <img src={passwordIcon} alt="" />
                                        </div>
                                        <div>
                                            <input
                                                className={styles.inputt}
                                                type="password"
                                                placeholder="Придумайте пароль"
                                                onChange={(e) => passwordHandler(e)}
                                                value={password}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        retryPasswordError !== '' &&
                                        <div className={styles.error}>
                                            {retryPasswordError}
                                            <img src={alert} alt="" />
                                        </div>
                                    }
                                    <div className={styles.email}>
                                        <div className={styles.imgg}>
                                            <img src={passwordIcon} alt="" />
                                        </div>
                                        <div>
                                            <input
                                                className={styles.inputt}
                                                type="password"
                                                placeholder="Повторите пароль"
                                                onChange={(e) => retryPasswordHandler(e)}
                                                value={passwordRetry}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className={(retryPasswordError === '' && passwordError === '' && emailError === '') ? styles.login : styles.noLogin}
                                onClick={signUpHandler}
                                disabled={(retryPasswordError === '' && passwordError === '' && emailError === '') ? false : true}
                            >
                                Создать аккаунт
                            </button>
                        </div>

                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}
export default observer(SignUp)