
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/settingsManagment.module.css'
import box from '../Images/boxforcheck.svg'
import { Context } from "..";
import { useParams } from "react-router-dom";
import checkedBox from '../Images/checkedBox.svg'
import CoursesService from "../service/CoursesService";
import checkBox from '../Images/checkBox.svg'
import { toast } from "react-toastify";

function ManagmentSettings() {
    const { courseStore } = useContext(Context)
    const params = useParams()
    const current = params.id
    const [cardCheck, setCardCheck] = useState(false)
    const [lessonsCheck, setLessonsCheck] = useState(false)
    const [moduleEmptyCheck, setModuleEmptyCheck] = useState(false)
    const [nameCheck, setNameCheck] = useState(false)
    const [descriptionCheck, setDescriptionCheck] = useState(false)
    const [chapterCheck, setChapterCheck] = useState(false)
    const [modules, setModules] = useState([])
    const [lessons, setLessons] = useState([])
    const [chapters, setChapters] = useState([])


    useEffect(() => {
        async function fetchData() {
            try {
                checkCourseCardHandler()
                await getCourse()
                await getModules()
            } catch (e) {
                console.log(e)
            }
        }
        fetchData()
    }, [])

    async function getCourse() {
        await courseStore.checkCourse(current)
    }

    async function getModules() {
        try {
            const response = await CoursesService.fetchCourseModules(current)
            const dataArray = response.data.modules; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setModules(dataArray);
            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setModules([]); // Установка пустого массива в случае ошибки
            }
            getLessons(dataArray)
        } catch (e) {
            console.log(e)
        }
    }

    async function getLessons(arr) {
        const prevArayy = []
        for (let modulee of arr) {
            try {
                const response = await CoursesService.fetchModuleLessons(modulee.id)
                const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
                if (Array.isArray(dataArray)) {
                    prevArayy.push(dataArray)

                } else {
                    console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                    setLessons([]); // Установка пустого массива в случае ошибки
                }
                getChapters(dataArray)

            } catch (e) {
                console.log(e)
            }
        }
        setLessons(prevArayy)
        checkForModulesLessonsHandler(prevArayy, arr.length)
        checkForEmptyModules(prevArayy)
        checkForNameHandler(arr, prevArayy)
        checkForDescriptionHandler(arr)
    }

    async function getChapters(lessons) {
        const prevArray = []
        const finalArr = []

        try {
            for (let chapters of lessons) {
                try {
                    const response = await CoursesService.fetchChapters(chapters.id)
                    const dataArray = response.data.chapters; // Предполагается, что courses - это массив в модели
                    if (Array.isArray(dataArray)) {
                        prevArray.push(dataArray)

                    } else {
                        console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                        setChapters([]); // Установка пустого массива в случае ошибки
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.log(e)
        }
        checkForLessonChapters(prevArray)
    }

    const checkCourseCardHandler = () => {
        if (courseStore.course.name && courseStore.course.course_content && courseStore.course.img && courseStore.course.additional_type && courseStore.course.description) {
            setCardCheck(true)
        }
    }

    const checkForModulesLessonsHandler = (prevArayy, moduleslength) => {
        const totalLength = prevArayy.reduce((accumulator, innerArray) => {
            return accumulator + innerArray.length;
        }, 0);
        if (moduleslength >= 3 && totalLength >= 9) {
            setLessonsCheck(true)
        }
    }

    const checkForEmptyModules = (lessons) => {
        const allNonEmpty = lessons.every(arr => arr.length > 0);
        setModuleEmptyCheck(allNonEmpty)
    }

    const checkForNameHandler = (modules, lessons) => {
        const modulesName = modules.every(arr => arr.name !== 'Новый Модуль')
        const lessonsName = lessons.flatMap(arr => arr).every(lesson => lesson.name !== 'Новый урок');
        setNameCheck(modulesName && lessonsName)
    }

    const checkForDescriptionHandler = (modules) => {
        const modulesDescription = modules.every(arr => arr.description !== 'Черновик')
        setDescriptionCheck(modulesDescription)
    }

    const checkForLessonChapters = (chapters) => {
        const allNonEmpty = chapters.every(arr => arr.length >= 3);
        setChapterCheck(allNonEmpty)
    }

    const publishHandler = () => {
        if (chapterCheck && descriptionCheck && nameCheck && moduleEmptyCheck && cardCheck && lessonsCheck) {
            courseStore.publishCourse(current)
        }
    }

    const unPublishHandler = async () => {
        try {
            CoursesService.unPublishCourse(current)
            toast.success('Курс снят с публикации', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            console.log(e)
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.settings}>
                    <div className={styles.publish}>
                        <p className={styles.zagolovok}>Размещение в каталоге</p>
                        {/* <div className={styles.price}>

                        </div> */}
                        <div className={styles.checkList}>
                            <p>Необходимо выполнить все условия, чтобы опубликовать курс</p>
                            <div>
                                <img src={cardCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}>Карточка курса заполнена</p>
                            </div>
                            <div>
                                <img src={lessonsCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}>Курс содержит не менее 3 модулей и 9 уроков (по 3 в каждом модуле)</p>
                            </div>
                            <div>
                                <img src={moduleEmptyCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}>Нет пустых модулей</p>
                            </div>
                            <div>
                                <img src={nameCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}>Название модулей и уроков заполнены</p>
                            </div>
                            <div>
                                <img src={descriptionCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}>Описание модулей заполнены</p>
                            </div>
                            <div>
                                <img src={chapterCheck ? checkedBox : checkBox} alt="" />
                                <p className={cardCheck ? styles.checked : styles.notChecked}> Уроки содержат не менее 3 разделов</p>
                            </div>
                        </div>
                        {
                            courseStore.course.status === 'published' &&
                            <button className={styles.unPublish} onClick={unPublishHandler}>Снять с публикации</button>
                        }
                        {
                            courseStore.course.status !== 'published' &&
                            <button className={(cardCheck && lessonsCheck && moduleEmptyCheck && nameCheck && descriptionCheck && chapterCheck) ? styles.publicButton : styles.notPublicButton} onClick={publishHandler}>Разместить курс</button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default observer(ManagmentSettings)