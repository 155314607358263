import { useContext } from "react"
import { Context } from ".."


const icons = [
    {
        id: 1,
        name: 'VPS',
    },
    {
        id: 2,
        name: 'Angular',
    },
    {
        id: 3,
        name: 'Bootstrap',
    },
    {
        id: 4,
        name: 'C',
    },
    {
        id: 5,
        name: 'C#',
    },
    {
        id: 6,
        name: 'C++',
    },
    {
        id: 7,
        name: 'GitHub',
    },
    {
        id: 8,
        name: 'Go',
    },
    {
        id: 9,
        name: 'HTML/CSS',
    },
    {
        id: 10,
        name: 'Java',
    },
    {
        id: 11,
        name: 'JavaScript',
    },
    {
        id: 12,
        name: 'MySQL',
    },
    {
        id: 13,
        name: 'Node.js',
    },
    {
        id: 14,
        name: 'PHP',
    },
    {
        id: 15,
        name: 'PostgreSQL',
    },
    {
        id: 16,
        name: 'Python',
    },
    {
        id: 17,
        name: 'React',
    },
    {
        id: 18,
        name: 'SQL Server',
    },
    {
        id: 19,
        name: 'Swift',
    },
    {
        id: 20,
        name: 'TypeScript',
    },
    {
        id: 21,
        name: 'Unity',
    },
    {
        id: 22,
        name: 'Visual Studio',
    },
    {
        id: 23,
        name: 'Vue.js',
    },
    {
        id: 24,
        name: 'Wordpress',
    },
    {
        id: 25,
        name: 'Visual Studio Code',
    },
    {
        id: 26,
        name: 'SQL',
    },
    {
        id: 27,
        name: 'Kotlin',
    },
    {
        id: 28,
        name: 'Kotlin',
    },
    {
        id: 29,
        name: 'Scala',
    },
    {
        id: 30,
        name: 'Pascal',
    },
    {
        id: 31,
        name: 'R',
    },
    {
        id: 32,
        name: 'Dart',
    },
    {
        id: 33,
        name: 'VBA',
    },
    {
        id: 34,
        name: '1C',
    },
    {
        id: 35,
        name: 'Bash',
    },
    {
        id: 36,
        name: 'Lua',
    },
    {
        id: 37,
        name: 'Rust',
    },
    {
        id: 38,
        name: 'Ruby',
    },
    {
        id: 39,
        name: 'Django',
    },
    {
        id: 40,
        name: 'Pandas',
    },
    {
        id: 41,
        name: 'Flask',
    },
    {
        id: 42,
        name: 'NumPy',
    },
    {
        id: 43,
        name: 'Flutter',
    },
    {
        id: 44,
        name: 'IOS',
    },
    {
        id: 45,
        name: 'Android',
    },
    {
        id: 46,
        name: 'Unreal Engine',
    },
    {
        id: 47,
        name: 'Game Design',
    },
    {
        id: 48,
        name: 'Frontend',
    },
    {
        id: 49,
        name: 'Backend',
    },
    {
        id: 50,
        name: 'UX/UI',
    },
    {
        id: 51,
        name: 'DevOps',
    },
    {
        id: 52,
        name: 'QA',
    },
    {
        id: 53,
        name: 'AI, Нейросети',
    },
    {
        id: 54,
        name: 'Для детей',
    },
    {
        id: 55,
        name: 'Системный анализ',
    },
    {
        id: 56,
        name: 'Информационная безопасность',
    },
    {
        id: 57,
        name: 'Творчество',
    },
    {
        id: 58,
        name: 'Бизнес и менеджмент',
    },
    {
        id: 59,
        name: 'Иностранные языки',
    },
    {
        id: 60,
        name: 'Технологии',
    },
    {
        id: 61,
        name: 'Ubuntu',
    },
    {
        id: 62,
        name: 'NginX',
    },
    {
        id: 63,
        name: 'Apache',
    },
    {
        id: 64,
        name: 'Битрикс24',
    },
    {
        id: 64,
        name: '1С-Битрикс',
    },
]

export { icons }