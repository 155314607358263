import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from '../styles/editCoursePage.module.css'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import { useEffect, useState } from "react";
import { COURSES_CONTENT, EDIT_LESSON } from "../utils";
import DocumentTitle from "react-document-title";
import checkBox from '../Images/checkBox.svg'
import pen from '../Images/pen.svg'
import structure from '../Images/structure.svg'
import catalog2 from '../Images/catalog2.svg'
import camera from '../Images/camera.svg'
import trash from '../Images/trash.svg'
import plus from '../Images/plus.svg'
import exit from '../Images/exit.svg'
import whitePlus from '../Images/whitePlus.svg'
import edit from '../Images/edit.svg'
import check from '../Images/check.svg'
import { icons } from "../data/coursesData";
import errorimg from '../Images/error.svg'
import axios from "axios";
import { toast } from "react-toastify";
import CoursesService from "../service/CoursesService";
import ManagmentSettings from "../components/ManagmentSettings";
import BurgerMenu from "../components/BurgerMenu";
import whiteTrash from "../Images/whiteTrash.svg";

function FreeCourses() {
    const { courseStore, store } = useContext(Context)
    const [name, setName] = useState(courseStore.course.name)
    const [ava, setAva] = useState(null)
    const [aboutCourse, setAboutCourse] = useState(courseStore.course.more_description)
    const [description, setDescription] = useState(courseStore.course.description)
    const [courseContent, setCourseContent] = useState([])
    const [type, setType] = useState('1')
    const [dopType, setDopType] = useState([])
    const [tags, setTags] = useState([])
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [panel, setPanel] = useState('main')
    const [showTags, setShowTegs] = useState(false)
    const [modules, setModules] = useState([])
    const [moduleName, setModuleName] = useState()
    const [editModuleName, setEditModuleName] = useState('none')
    const [updateLessons, setUpdateLessons] = useState(false)
    const [time, setTime] = useState(courseStore.course.time)
    const [shortDescriptionLeft, SetShortDescriptionLeft] = useState(255)
    const [moreDescriptionLeft, SetMoreDescriptionLeft] = useState(1000)

    const params = useParams()
    const current = params.id

    const location = useLocation()
    const { from } = location.state || {}

    useEffect(() => {
        getCourse()
        getModules()
        if (from === '/courseStructure') {
            setPanel('structure')
        }
    }, [])

    async function getCourse() {
        try {
            await courseStore.fetchUserCourse(current)
            setAva(courseStore.course.img)
            setDopType([...courseStore.course.additional_type])
            if (isJsonString(courseStore.course.course_content)) {
                const parsedConent = JSON.parse(courseStore.course.course_content)
                setCourseContent(parsedConent)
            } else {
                setCourseContent([])
            }
            setName(courseStore.course.name)
            setDescription(courseStore.course.description)
            setAboutCourse(courseStore.course.more_description)
            setTime(courseStore.course.time)
            SetShortDescriptionLeft(255 - courseStore.course.description.length)
            SetMoreDescriptionLeft(1000 - courseStore.course.more_description.length)
        } catch (e) {
            console.log(e)
        }
    }

    const createModule = async () => {
        try {
            await courseStore.createModule(current)
        } catch (e) {
            console.log(e)
        }
        getModules()
    }

    const deleteModule = async () => {
        try {
            const lastModule = modules[modules.length - 1].id
            await courseStore.deleteModule(lastModule)
        } catch (e) {
            console.log(e)
        }
        getModules()
    }



    const createLesson = async (moduleId, moduleNumber) => {
        try {
            await courseStore.createLesson(moduleId, moduleNumber)

        } catch (e) {
            console.log(e)
        }
        getModules()
        setUpdateLessons(prevState => !prevState)
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async function getModules() {
        try {
            const response = await CoursesService.fetchCourseModules(current)
            const dataArray = response.data.modules; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setModules(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setModules([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }


    const panelHandler = (panel) => {
        setPanel(panel)
    }


    const addContent = () => {
        setCourseContent([...courseContent, { description: '', number: Date.now() }])
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const changeInfo = (key, value, number) => {
        setCourseContent(courseContent.map(i => (i.number === number ? { ...i, [key]: value } : i)))
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const removeInfo = (number) => {
        setCourseContent(courseContent.filter(i => (i.number !== number)))
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const addDopTypesHandler = (id) => {
        setDopType(prev => [...prev, id])
        setShowTegs(false)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const removedopTegs = (index) => {
        setDopType(dopType.filter(item => (item !== index)))
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const descriptionHandler = (e) => {
        SetShortDescriptionLeft(255 - e.target.value.length)
        setDescription(e.target.value)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const aboutCourseHandler = (e) => {
        SetMoreDescriptionLeft(1000 - e.target.value.length)
        setAboutCourse(e.target.value)
        setError('Данные были изменены. Не забудьте сохранить.')
    }


    const saveData = () => {
        const formdata = new FormData()
        formdata.append('courseId', courseStore.course.id)
        formdata.append('name', name)
        formdata.append('description', description)
        formdata.append('courseContent', JSON.stringify(courseContent))
        formdata.append('more_description', aboutCourse)
        formdata.append('type', type)
        formdata.append('time', time)
        formdata.append('additional_type', JSON.stringify(dopType))
        courseStore.saveCourseData(formdata)
        setError('')
    }



    const findTag = (e) => {
        let pattern = e.target.value.toLowerCase(); // Приводим строку поиска к нижнему регистру
        let result = icons.filter(item => item.name.toLowerCase().startsWith(pattern));
        setTags(result);
    }



    const navigateHandler = (path, id) => {
        if (path === 'lesson') {
            navigate(EDIT_LESSON + `/${id}`)
        }
    }

    const uploadImageHandler = async (e) => {
        const img = e.target.files[0]
        const formData = new FormData()
        formData.append('img', img)
        formData.append('course_id', current)
        try {
            const response = await CoursesService.uploadCourseImage(formData)
            toast.success('Изображение загружено', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setAva(response.data.course.img)

        } catch (e) {
            console.log(e)
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const showTagsHandler = () => {
        setShowTegs(true)
    }

    const deleteImageHandler = async () => {
        const formData = new FormData()
        formData.append('course_id', current)
        try {
            const response = await CoursesService.deleteCourseImage(formData)
            toast.success('Изображение удалено', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setAva(null)

        } catch (e) {
            console.log(e)
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const saveModuleNameHandler = async (id) => {
        const formData = new FormData()
        formData.append('module_id', id)
        formData.append('name', moduleName)
        try {
            const response = await CoursesService.changeModuleName(formData)
            getModules()
            setEditModuleName('none')
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>

            <DocumentTitle title={courseStore.course.name}>

                <div className={`${styles.container}`}>

                    <div>
                        <Header />

                    </div>

                    <div className={styles.content}>
                        <BurgerMenu />

                        {
                            <>
                                {
                                    current == courseStore.course.id &&
                                    <div className={styles.settings}>
                                        <div className={styles.navPanel}>
                                            <p>{courseStore.course.name}</p>
                                            <div className={styles.navButtons}>
                                                <button onClick={() => panelHandler('main')} className={panel === 'main' ? `${styles.selected}` : false}>
                                                    <img src={pen} alt="" />
                                                    <p>Основное</p>
                                                </button>
                                                <button onClick={() => panelHandler('structure')} className={panel === 'structure' ? `${styles.selected}` : false}>
                                                    <img src={structure} alt="" />
                                                    <p>Структура курса</p>
                                                </button>
                                                <button onClick={() => panelHandler('publicate')} className={panel === 'publicate' ? `${styles.selected}` : false}>
                                                    <img src={catalog2} alt="" />
                                                    <p>Размещение в каталоге</p>
                                                </button>
                                            </div>
                                            <div className={styles.saveAndError}>
                                                {
                                                    error &&
                                                    <div className={styles.error}>
                                                        <img src={errorimg} alt="" />
                                                        <div>
                                                            <p>Есть несохраненные изменения</p>
                                                            <div>
                                                                Не забудьте сохранить их перед выходом
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className={styles.saveButton} onClick={saveData}>
                                                    Сохранить изменения
                                                </div>
                                            </div>

                                        </div>
                                        <div className={styles.info}>
                                            {
                                                panel === 'main' &&
                                                <>
                                                    <div className={styles.mainCourseInfo}>
                                                        <p>Внешний вид</p>
                                                        <div className={styles.mainInfo}>
                                                            <div className={styles.courseImg}>
                                                                <p>Облложка курса</p>
                                                                <img src={ava !== null ? process.env.REACT_APP_API_URL + ava : camera} alt="" />
                                                                <label className={styles.inputFiles}>
                                                                    <input
                                                                        type="file"
                                                                        id="img"
                                                                        onChange={(e) => uploadImageHandler(e)}
                                                                    />
                                                                    <span className={styles.inputButton} >
                                                                        <img src={camera} alt="" />
                                                                        Загрузить
                                                                    </span>
                                                                </label>
                                                                <button className={styles.deleteFile} onClick={deleteImageHandler}>
                                                                    <img src={trash} alt="" />
                                                                    Удалить
                                                                </button>
                                                            </div>
                                                            <div className={styles.aboutCourse}>
                                                                <p>Общая информация</p>
                                                                <div className={styles.descriptionNameTags}>
                                                                    <p>Название курса</p>
                                                                    <input
                                                                        type="text"
                                                                        className={styles.courseName}
                                                                        placeholder="Введите текст"
                                                                        onChange={(e) => nameHandler(e)}
                                                                        value={name}
                                                                    />
                                                                    <p className={styles.abc}>Кратко о курсе</p>
                                                                    <textarea
                                                                        className={styles.shortDescription}
                                                                        placeholder="Введите текст"
                                                                        name=""
                                                                        id=""
                                                                        onChange={(e) => descriptionHandler(e)}
                                                                        value={description}
                                                                        maxLength={255}
                                                                    >
                                                                    </textarea>
                                                                    <p>Осталось {shortDescriptionLeft} символов</p>
                                                                    <p className={styles.tagName}>Время прохождения (час)</p>
                                                                    <input
                                                                        type="number"
                                                                        className={styles.courseName}
                                                                        placeholder="Введите время"
                                                                        onChange={(e) => setTime(e.target.value)}
                                                                        value={time}
                                                                    />
                                                                    <p className={styles.tagName}>Теги</p>
                                                                    <div className={styles.tags}>
                                                                        <div className={styles.tagBlock}>
                                                                            <button onClick={showTagsHandler}>
                                                                                <img src={plus} alt="" />
                                                                            </button>
                                                                            {
                                                                                dopType.length !== 0 &&
                                                                                icons.filter(item => dopType.includes(item.id)).map(item => (
                                                                                    <div key={item.id}>

                                                                                        <div className={styles.tegblock}>
                                                                                            {item.name}
                                                                                            <img
                                                                                                src={exit} alt=""
                                                                                                onClick={() => removedopTegs(item.id)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ))

                                                                            }

                                                                        </div>
                                                                        {
                                                                            showTags &&
                                                                            <div className={styles.searchTags}>
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => findTag(e)}
                                                                                    placeholder="Поиск"
                                                                                />
                                                                                {
                                                                                    tags.length !== 0 &&
                                                                                    <div className={styles.tagNames}>
                                                                                        {
                                                                                            tags.map((item, index) => (
                                                                                                <div key={index} onClick={() => addDopTypesHandler(item.id)} >
                                                                                                    {item.name}
                                                                                                </div>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={styles.moreDescription}>
                                                                <p className={styles.mdp}>Подробно о курсе</p>
                                                                <textarea
                                                                    className={styles.longDescription}
                                                                    placeholder="Например, об авторе, длительности, преимуществе обучения, результате, который будет после прохождения курса."
                                                                    name=""
                                                                    id=""
                                                                    onChange={(e) => aboutCourseHandler(e)}
                                                                    value={aboutCourse}
                                                                    maxLength={1000}
                                                                >
                                                                </textarea>
                                                                <p className={styles.lettersLeft}>Осталось {moreDescriptionLeft} символов</p>
                                                                <p className={styles.soderzh}>Содержание курса</p>
                                                                {
                                                                    courseContent.length !== 0 &&
                                                                    <div className={styles.popts}>
                                                                        {
                                                                            courseContent.map((item, index) => (
                                                                                <div key={item.number} className={styles.popt} id="popts">
                                                                                    <p>{index + 1}.</p>
                                                                                    <input
                                                                                        className={styles.logininput}
                                                                                        type="text"
                                                                                        id="content"
                                                                                        value={item.description}
                                                                                        placeholder={item.description}
                                                                                        onChange={(e) => changeInfo('description', e.target.value, item.number)}
                                                                                    />
                                                                                    <button className={styles.removeButton} onClick={() => removeInfo(item.number)}>Удалить</button>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }
                                                                <button className={styles.addSoderzh} onClick={addContent}>
                                                                    <img src={plus} alt="" />
                                                                    Добавить
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                            {
                                                panel === 'structure' &&
                                                <>
                                                    <div className={styles.courseStrucure}>
                                                        <p>Структура курса</p>
                                                        <div className={styles.structure}>
                                                            <p>
                                                                Содержание курса
                                                            </p>
                                                            {
                                                                modules.length !== 0 &&
                                                                <div className={styles.modulesAndLessons}>
                                                                    {
                                                                        modules.length !== 0 &&
                                                                        modules.map((item, index) => (
                                                                            <>
                                                                                <div className={styles.module} key={index}>

                                                                                    {
                                                                                        editModuleName !== item.id &&
                                                                                        <p>
                                                                                            {item.name}
                                                                                            <img src={edit} alt="" onClick={() => setEditModuleName(item.id)} />
                                                                                        </p>
                                                                                    }
                                                                                    {
                                                                                        editModuleName === item.id &&
                                                                                        <>
                                                                                            <div className={styles.editModuleName}>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    onChange={(e) => setModuleName(e.target.value)}
                                                                                                />
                                                                                                <img src={check} alt="" onClick={() => saveModuleNameHandler(item.id)} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    <div className={styles.lessons}>
                                                                                        <ListLessons id={item.id} navigateCB={navigateHandler} updateLessons={updateLessons} />
                                                                                    </div>

                                                                                    <button className={styles.addLesson} onClick={() => createLesson(item.id, item.number)}>
                                                                                        <img src={whitePlus} alt="" />
                                                                                        Добавить урок
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        ))

                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <button className={styles.deleteModule} onClick={deleteModule}>
                                                            <img src={whiteTrash} alt="" />
                                                            Удалить модуль
                                                        </button>
                                                        <button className={styles.addModule} onClick={createModule}>
                                                            <img src={whitePlus} alt="" />
                                                            Добавить модуль
                                                        </button>
                                                    </div>
                                                </>
                                            }
                                            {
                                                panel === 'publicate' &&
                                                <ManagmentSettings />
                                            }
                                        </div>
                                    </div>}
                            </>
                        }
                    </div>

                    <div>
                        <Footer />
                    </div>
                </div >
            </DocumentTitle>

        </>
    )
}

function ListLessons({ id, navigateCB, updateLessons }) {
    const { courseStore } = useContext(Context)
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        getLessons(id)
    }, [id, updateLessons])

    const navigateHandler = (path, id) => {
        navigateCB(path, id)
    }

    const deleteLesson = async () => {
        try {
            const lastLesson = lessons[lessons.length - 1].id
            await courseStore.deleteLesson(lastLesson)
        } catch (e) {
            console.log(e)
        }
        getLessons(id)
    }

    async function getLessons(id) {
        try {
            const response = await CoursesService.fetchModuleLessons(id)
            const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setLessons(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setLessons([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {

                lessons.map((item, index) => (
                    <>
                        <div className={styles.lesson} onClick={() => navigateHandler('lesson', item.id)}>
                            <p>
                                {item.name}
                            </p>
                            <img src={edit} alt="" />
                        </div>
                    </>
                ))
            }
            <button className={styles.deleteLesson} onClick={() => deleteLesson()}>
                <img src={whiteTrash} alt="" />
                Удалить урок
            </button>
        </>
    )
}

export default observer(FreeCourses)