import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/myActiveCourse.module.css'
import { Context } from "..";
import CoursesService from "../service/CoursesService";
import { useNavigate, useParams } from "react-router-dom";
import { COURSE_CARD, FREE_COURSE, LEARN_MAIN_PAGE } from "../utils";
import arrowDown from '../Images/arrowDown.svg'
import arrowUp from '../Images/arrowUp.svg'
import check from '../Images/check.svg'
import triangle from '../Images/triangle.svg'
import miniheart from '../Images/miniheart.svg'
import portfolio from '../Images/portfolio.svg'

function MyActiveCourse() {

    const { courseStore, store } = useContext(Context)

    const [courses, setCourses] = useState([])
    const [favoriteCourses, setFavoriteCourses] = useState([])
    const [doneCourses, setDoneCourses] = useState([])
    const [showCourses, setShowCourses] = useState('draft')

    const navigate = useNavigate()

    const params = useParams()
    const current = params.id

    const navigateHandler = (path, id) => {
        if (path === 'courseCard') {
            navigate(LEARN_MAIN_PAGE + '/' + id)
        }
        if (path === 'favoriteCourse') {
            navigate(COURSE_CARD + '/' + id)
        }
    }
    useEffect(() => {
        getCourses()
        GetFavoriteCourses()
        GetDoneCourses()
    }, [])

    async function getCourses() {
        try {
            const response = await CoursesService.fetchActiveCourses(current)
            const coursesData = response.data.courses
            if (Array.isArray(coursesData)) {
                setCourses(coursesData)
            }
        } catch (e) {
            setCourses([])
            console.log(e)
        }
    }

    async function GetFavoriteCourses() {
        const response = await CoursesService.fetchAllFavorites(current)
        const coursesArray = response.data.courses
        if (Array.isArray(coursesArray)) {
            setFavoriteCourses(coursesArray)
        }
        else {
            setFavoriteCourses([])
        }
    }

    async function GetDoneCourses() {
        const response = await CoursesService.getDoneCourses(current)
        const coursesArray = response.data.courses
        if (Array.isArray(coursesArray)) {
            setDoneCourses(coursesArray)
        }
        else {
            setDoneCourses([])
        }
    }

    const showCoursesHandler = (type) => {
        setShowCourses(type)
    }

    return (
        <>
            <div className={styles.content}>
                <p>Мое обучение</p>
                <div className={styles.activeCourses}>
                    <button className={styles.typeCourse} onClick={() => showCoursesHandler('portfolio')}>
                        <img src={portfolio} alt="" className={styles.leftIcon} />
                        Портфолио
                        <img src={showCourses === 'portfolio' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                    </button>
                    <>
                        <button className={styles.typeCourse} onClick={() => showCoursesHandler('published')}>
                            <img src={triangle} alt="" className={styles.leftIcon} />
                            Прохожу сейчас
                            <img src={showCourses === 'published' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button>
                        {
                            (showCourses === 'published' && courses.length !== 0) &&
                            <div className={styles.draftCourses}>
                                {

                                    courses.map((item, index) => (
                                        <>
                                            <div key={index} className={styles.courseBlock} onClick={() => navigateHandler('courseCard', item.id)}>
                                                <img src={process.env.REACT_APP_API_URL + item.img} alt="" className={styles.courseImg} />
                                                <p>{item.name}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                    </>
                    <>
                        <button className={styles.typeCourse} onClick={() => showCoursesHandler('favorites')}>
                            <img src={miniheart} alt="" className={styles.leftIcon} />
                            Избранные
                            <img src={showCourses === 'favorites' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button>
                        {
                            (showCourses === 'favorites' && favoriteCourses.length !== 0) &&
                            <div className={styles.draftCourses}>
                                {

                                    favoriteCourses.map((item, index) => (
                                        <>
                                            <div key={index} className={styles.courseBlock} onClick={() => navigateHandler('favoriteCourse', item.id)}>
                                                <img src={process.env.REACT_APP_API_URL + item.img} alt="" className={styles.courseImg} />
                                                <p>{item.name}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                    </>
                    <>
                        <button className={styles.typeCourse} onClick={() => showCoursesHandler('done')}>
                            <img src={check} alt="" className={styles.leftIcon} />
                            Пройденные
                            <img src={showCourses === 'done' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button>
                        {
                            (showCourses === 'done' && doneCourses.length !== 0) &&
                            <div className={styles.draftCourses}>
                                {

                                    doneCourses.map((item, index) => (
                                        <>
                                            <div key={index} className={styles.courseBlock} onClick={() => navigateHandler('favoriteCourse', item.id)}>
                                                <img src={process.env.REACT_APP_API_URL + item.img} alt="" className={styles.courseImg} />
                                                <p>{item.name}</p>
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                    </>
                    {/* {
                        courses.length !== 0 &&
                        courses.map((item, index) => (
                            <>
                                <div key={item.id} className={styles.course}>
                                    <img src={process.env.REACT_APP_API_URL + item.img} alt="" />
                                    <div className={styles.info}>
                                        <p>{item.name}</p>
                                        <button onClick={() => navigateHandler(item.id)}>Продолжить</button>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                    {
                        !courses.length &&
                        <div>
                            Нет активных курсов
                        </div>
                    } */}
                </div>
            </div>
        </>
    )
}

export default observer(MyActiveCourse)