import React, { useContext, useEffect, useState } from "react";
import styles from "../styles/editLesson.module.css";
import { observer } from "mobx-react-lite";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Context } from "..";
import { useNavigate, useParams } from "react-router-dom";
import CoursesService from "../service/CoursesService";
import plus from "../Images/plus.svg";
import "react-quill/dist/quill.snow.css";
import TextEditor from "../components/TextEditor";
import ChapterTest from "../components/ChapterTest";
import ChapterMultiTest from "../components/ChapterMultiTest";
import DocumentTitle from "react-document-title";
import LessonCodeEditor from "../components/LessonCodeEditor";
import VideoEditor from "../components/VideoEditor";
import arrowLeft from '../Images/arrowLeft.svg'
import video2 from '../Images/video2.svg'
import code from '../Images/code.svg'
import text from '../Images/text.svg'
import errorimg from '../Images/error.svg'
import radio from '../Images/radio.svg'
import checkButton from '../Images/checkButton.svg'
import { FREE_COURSE } from "../utils";
import trash from '../Images/trash.svg'
import { toast } from "react-toastify";
import BurgerMenu from "../components/BurgerMenu";

function EditLesson() {
    const { store, courseStore } = useContext(Context);
    const params = useParams();
    const current = params.id;
    const [chapters, setChapters] = useState([]);
    const [chapter, setChapter] = useState({});
    const [chaptersError, setChaptersError] = useState();
    const [addChapterClicker, setAddChapterClicker] = useState(false);
    const [lessonName, setLessonName] = useState(courseStore.lesson.name);
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [chapterInfo, setChapterInfo] = useState([])
    const [chapterContent, setChapterContent] = useState([]);

    useEffect(() => {
        getLesson();
        getChapters();
    }, [addChapterClicker]);

    async function getLesson() {
        try {
            await courseStore.fetchLesson(current);
            setLessonName(courseStore.lesson.name)
        } catch (e) {
            console.log(e);
        }
    }

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async function getChapters() {
        try {
            const response = await CoursesService.fetchChapters(current);
            const dataArray = response.data.chapters; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setChapters(dataArray);
            } else {
                console.error(
                    "Ожидался массив, но получен другой тип данных:",
                    dataArray
                );
                setChapters([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e);
        }
    }

    const chapterHandler = (item, index) => {
        setChapter(item);
        setAddChapterClicker(addChapterClicker === true ? false : true);
        localStorage.setItem("chapterId", item.id);
        if (!isJsonString(item.content)) {
            setChapterContent([]);
        } else {
            const parsedContent = JSON.parse(item.content);
            setChapterContent([parsedContent]);
        }
    };

    const navigateHandler = () => {
        navigate(FREE_COURSE + '/' + courseStore.course.id, { state: { from: '/courseStructure' } })
    }

    const addElement = async (tag) => {
        try {
            await courseStore.createChapter(current, tag);

        } catch (e) {
            console.log(e)

        }

        getChapters()
    };

    const RenderArea = (i) => {
        return (
            <>
                {i.tag === "text" && (
                    <>
                        <div>
                            <TextEditor
                                chapter={i}
                                onTextHandler={textDescriptionHandler}
                                id={chapter.id}
                            />
                        </div>
                    </>
                )}
                {i.tag === "test" && (
                    <>
                        <div>
                            <ChapterTest
                                chapter={i}
                                id={chapter.id}
                                onTestHandler={testDescriptionHandler}
                            />
                        </div>
                    </>
                )}
                {i.tag === "testMulti" && (
                    <>
                        <div>
                            <ChapterMultiTest
                                chapter={i}
                                id={chapter.id}
                                onTestHandler={testDescriptionHandler}
                            />
                        </div>
                    </>
                )}
                {i.tag === "codeEditor" && (
                    <>
                        <div className={styles.codeEditorShow}>
                            <LessonCodeEditor
                                chapter={i}
                                id={chapter.id}
                                onCodeHandler={codeDescriptionHandler}
                            />
                        </div>
                    </>
                )}
                {i.tag === "video" && (
                    <>
                        <div>
                            <VideoEditor
                                chapter={i}
                                onTextHandler={textDescriptionHandler}
                                id={chapter.id}
                            />
                        </div>
                    </>
                )}
            </>
        );
    };


    const textDescriptionHandler = (result) => {
        setChapterInfo(result);
        setError('Данные были изменены. Не забудьте сохранить.')

    };
    const testDescriptionHandler = (result) => {
        setChapterInfo(result);
        setError('Данные были изменены. Не забудьте сохранить.')


    };

    const codeDescriptionHandler = (result) => {
        setChapterInfo(result);
        setError('Данные были изменены. Не забудьте сохранить.')

    };

    const saveChapterHandler = () => {
        const formdata = new FormData();
        const chapterData = JSON.stringify(chapterInfo);

        if (chapterInfo === "Контент") {
            formdata.append("chapter_info", chapterInfo);
        } else {
            formdata.append("chapter_info", chapterData);
        }
        formdata.append("chapter_id", chapter.id);
        courseStore.saveChapter(formdata);
        courseStore.saveLesson(courseStore.lesson.id, lessonName);
        setError('')

    };

    const deleteChapter = async () => {
        try {
            await CoursesService.deleteChapter(chapters[chapters.length - 1].id)
            toast.success('Глава удалена', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            console.log(e)
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
        getChapters()
    }


    return (
        <DocumentTitle title={courseStore.lesson.name}>
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <BurgerMenu />
                        {current == courseStore.lesson.id && (
                            <>
                                <div className={styles.lesson}>
                                    <div className={styles.navPanel}>
                                        <div className={styles.navPanelButtons}>
                                            <div className={styles.nameAndBack}>
                                                <button onClick={() => navigateHandler()}>
                                                    <img src={arrowLeft} alt="" />
                                                </button>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setLessonName(e.target.value)}
                                                    value={lessonName}
                                                />
                                            </div>
                                            <div className={styles.lessonElements}>
                                                <p>Добавить в урок</p>
                                                <div className={styles.elements}>
                                                    <button className={styles.video} onClick={() => addElement("video")}>
                                                        <img src={video2} alt="" />
                                                        <p>Видео</p>
                                                    </button>
                                                    <div className={styles.editors}>
                                                        <button className={styles.text} onClick={() => addElement("text")}>
                                                            <img src={text} alt="" />
                                                            <p>Текст</p>
                                                        </button>
                                                        <button className={styles.code} onClick={() => addElement("codeEditor")}>
                                                            <img src={code} alt="" />
                                                            <p>Редактор кода</p>
                                                        </button>
                                                    </div>
                                                    <button className={styles.video} onClick={() => addElement("testMulti")}>
                                                        <img src={checkButton} alt="" />
                                                        <p>Тест<p>(Несколько верных ответов)</p></p>
                                                    </button>
                                                    <button className={styles.video} onClick={() => addElement("test")}>
                                                        <img src={radio} alt="" />
                                                        <p>Тест<p>(1 верный ответ)</p></p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.saveAndError}>
                                            {
                                                error &&
                                                <div className={styles.error}>
                                                    <img src={errorimg} alt="" />
                                                    <div>
                                                        <p>Есть несохраненные изменения</p>
                                                        <div>
                                                            Не забудьте сохранить урок перед выходом
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className={styles.saveButton} onClick={saveChapterHandler}>
                                                Сохранить изменения
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.lessonInfo}>
                                        <div className={styles.chapters}>
                                            {
                                                chapters.length !== 0 &&
                                                chapters.map((item, index) => (
                                                    <button
                                                        className={`${localStorage.getItem("chapterId") == item.id
                                                            ? styles.selectedChapter
                                                            : styles.chapter
                                                            }`}
                                                        onClick={() => chapterHandler(item, index)}
                                                    >
                                                        {index + 1}
                                                        {
                                                            localStorage.getItem("chapterId") == item.id &&
                                                            <p>раздел</p>
                                                        }
                                                    </button>
                                                ))
                                            }
                                            {
                                                chapters.length !== 0 &&
                                                <button className={styles.deleteChapter} onClick={deleteChapter}>
                                                    <img src={trash} alt="" />
                                                </button>
                                            }
                                        </div>
                                        <div className={styles.chapterInfo}>
                                            <div className={styles.chapterContent}>
                                                {chapterContent.map((i) => (
                                                    <div key={i.number} className={styles.popt} id="popts">
                                                        <div className={`${styles.cont} ${styles.glass}`}>
                                                            {RenderArea(i)}
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>
    );
}

export default observer(EditLesson);