import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from '../styles/editLesson.module.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Context } from "..";
import axios from "axios";
import CoursesService from "../service/CoursesService";
import trash from '../Images/trash.svg'
import checkForbutton from '../Images/checkForbutton.svg'


function ChapterTest({ chapter, onTestHandler, id }) {
    const [testDescription, setTestDescription] = useState([])
    const [testContent, setTestContent] = useState(chapter)

    const quillRef = useRef(null);
    const { courseStore } = useContext(Context)

    useEffect(() => {
        onTestHandler(testContent)
    }, [testContent])

    useEffect(() => {
        setTestContent({ ...testContent, 'description': testDescription })
    }, [testDescription])

    useEffect(() => {
        getChapter()
    }, [])

    async function getChapter() {
        try {
            await courseStore.fetchChapter(id)
        } catch (e) {
            console.log(e)
        }
    }

    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                const savedRange = range ? range.index : 0;
                const formData = new FormData();
                formData.append('img', file);
                formData.append('pos', savedRange);
                formData.append('chapter_id', id);
                try {
                    const response = await CoursesService.uploadLessonImage(formData)
                    const chaptData = response.data.chapter.imgs
                    const chaptImg = JSON.parse(chaptData)[0].fileName
                    const imgPos = JSON.parse(chaptData)[0].pos

                    if (quillRef.current) {
                        const range = quillRef.current.getEditor().getSelection(true);
                        quillRef.current.getEditor().insertEmbed(imgPos, 'image', process.env.REACT_APP_API_URL + chaptImg);
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                    alert("Ошибка загрузки изображения. Пожалуйста, попробуйте еще раз.");
                }
            }
        };
    };

    useEffect(() => {
        if (chapter.description) {
            setTestDescription(chapter.description)
        }
    }, [])

    const handleChangeTestText = (value) => {

        changeInfo('name', value, chapter.number);
    };

    const changeInfo = (key, value, number) => {
        setTestContent({ ...chapter, [key]: value })

    }

    const addTestButton = (tag) => {
        setTestDescription([...testDescription, { description: '', number: Date.now(), tag: tag }])
    }

    const changeTestInfo = (key, value, number) => {
        setTestContent({ ...testContent, [key]: value })
    }

    const changeInfoTestButton = (key, value, number) => {
        setTestDescription(testDescription.map(i => (i.number === number ? { ...i, [key]: value } : i)))
    }

    const concatData = (key, value, number) => {
        setTestContent({ ...testContent, 'description': testDescription })
        concatData('description', testDescription, chapter.number)
    }


    const removeInfoTestButton = (number) => {
        setTestDescription(testDescription.filter(i => (i.number !== number)))
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                ['link', 'image', 'code-block'],
                ['clean']
            ],
            handlers: {
                'image': handleImageUpload
            }
        }
    }), [])

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'align',
        'link', 'image', 'code-block'
    ];


    return (
        <>
            <div className={`${styles.testContainer}`}>
                <div className={styles.textTest}>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={testContent.name}
                        placeholder="Введите содержание теста"
                        onChange={handleChangeTestText}
                        formats={formats}
                        modules={modules}
                    />
                </div>
                <div className={styles.testAnswers}>
                    {
                        testDescription.map((i) => (
                            <>
                                <div className={styles.testContent}>
                                    <div key={i.number}>
                                        <input
                                            onChange={(e) => changeTestInfo('answer', e.target.value, chapter.number)}
                                            type='radio'
                                            id={`option-${i.number}`}
                                            name="option"
                                            value={i.number}
                                            checked={i.number == testContent.answer}
                                            className={styles.radioBtn}
                                        />
                                        <label htmlFor={`option-${i.number}`}>
                                            <input
                                                type="text"
                                                className={styles.testInput}
                                                onChange={(e) => changeInfoTestButton('description', e.target.value, i.number)}
                                                value={i.description}
                                            />
                                        </label>
                                    </div>
                                    <button className={styles.removeButton} onClick={() => removeInfoTestButton(i.number)}>
                                        <img src={trash} alt='' />
                                    </button>
                                </div>
                            </>
                        ))
                    }
                    <button className={styles.addTestBtn} onClick={() => addTestButton('test')}>Добавить вариант ответа</button>
                </div>
            </div>
        </>
    )
}

export default observer(ChapterTest)