import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/favoriteCourses.module.css'
import Header from "../components/Header";
import Footer from "../components/Footer";
import CoursesService from "../service/CoursesService";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import trash from '../Images/trash.svg'
import clock from '../Images/clock.svg'
import { toast } from "react-toastify";
import DocumentTitle from "react-document-title";
import BurgerMenu from "../components/BurgerMenu";
import { COURSE_CARD } from "../utils";

function FavoritesCourses() {
    const { store, courseStore } = useContext(Context)
    const params = useParams()
    const current = params.id
    const [courses, setCourses] = useState([])

    const navigate = useNavigate()
    useEffect(() => {
        GetFavoriteCourses()
    }, [])

    async function GetFavoriteCourses() {
        const response = await CoursesService.fetchAllFavorites(current)
        const coursesArray = response.data.courses
        if (Array.isArray(coursesArray)) {
            setCourses(coursesArray)
        }
        else {
            setCourses([])
        }
    }

    const navigateHandler = (path, id) => {
        if (path === 'course') {
            navigate(COURSE_CARD + '/' + id)
        }
    }

    const addToFavoriteHandler = async (id) => {
        try {
            const response = await CoursesService.addToFavorite(id, store.user.id)
            toast.success('Успех', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            GetFavoriteCourses()
        } catch (e) {
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            console.log(e)
        }
    }

    return (
        <>
            <DocumentTitle title='Избранное'>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <BurgerMenu />

                        {
                            store.isAuth &&
                            <div className={styles.favorites}>
                                <p>Избранные курсы</p>
                                <div className={styles.courses}>
                                    {
                                        courses.length !== 0 &&
                                        courses.map((item, index) => (
                                            <>
                                                <div className={styles.course} key={index}>
                                                    <div className={styles.img}>
                                                        <img src={process.env.REACT_APP_API_URL + item.img} alt="" />
                                                    </div>
                                                    <div className={styles.info}>
                                                        <div className={styles.name}>
                                                            <p onClick={() => navigateHandler('course', item.id)}>{item.name}</p>
                                                            <button onClick={() => addToFavoriteHandler(item.id)}>
                                                                <img src={trash} alt="" />
                                                            </button>
                                                        </div>
                                                        <div className={styles.time}>
                                                            <img src={clock} alt="" />
                                                            <p>{item.time}</p>
                                                        </div>
                                                        <div className={styles.price}>
                                                            <p>{item.price === '0' ? 'Бесплатно' : item.price}</p>
                                                            <button>
                                                                Выбрать
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }
                                    {
                                        courses.length === 0 &&

                                        <p>Пустоватенько тут как то</p>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>
        </>
    )
}

export default observer(FavoritesCourses)