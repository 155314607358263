import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import styles from '../styles/userSettings.module.css'
import { Context } from "..";
import camera from '../Images/camera.svg'
import trash from '../Images/trash.svg'
import { toast } from "react-toastify";
import mail2 from '../Images/mail2.svg'
import passwordIcon from '../Images/passwordIcon.svg'
import alert from '../Images/alert.svg'
import AuthService from "../service/AuthService";



function UserSettings() {

    const { store } = useContext(Context)
    const [name, setName] = useState(store.user.name)
    const [surname, setSurname] = useState(store.user.surname)
    const [city, setCity] = useState(store.user.city)
    const [dob, setDob] = useState(store.user.dob)
    const [username, setUsername] = useState(store.user.username)
    const [email, setEmail] = useState(store.user.email)
    const [ava, setAva] = useState(store.user.img)
    const [forget, setForget] = useState('none')
    const [password, setPassword] = useState('')
    const [emailError, setEmailError] = useState(true)
    const [textEmailError, setTextEmailError] = useState('Обязательное поле')
    const [sendCode, setSendCode] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [NPErrorText, setNPErrorText] = useState('Обязательное поле')
    const [newPasswordError, setNewPasswordError] = useState(true)
    const [retryNPErrorText, setRetryNPErrorText] = useState('Обязательное поле')
    const [retryNewPasswordError, setRetryNewPasswordError] = useState(true)
    const [changeCode, setChangeCode] = useState('')
    const [changeCodeError, setChangeCodeError] = useState(true)
    const [changeCodeTextError, setChangeCodeTextError] = useState('Обязательное поле')
    const [newEmail, setNewEmail] = useState('')

    const uploadImageHandler = async (e) => {
        const img = e.target.files[0]
        const formData = new FormData()
        formData.append('img', img)
        formData.append('user_id', store.user.id)
        try {
            const response = await AuthService.uploadImage(formData)
            toast.success('Изображение загружено', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setAva(response.data.user.img)

        } catch (e) {
            console.log(e)
            toast.error('Ошибка', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const deleteImageHandler = async () => {
        const formData = new FormData()
        formData.append('user_id', store.user.id)
        try {
            const response = await AuthService.deleteImage(formData)
            toast.success('Изображение удалено', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setAva(null)

        } catch (e) {
            console.log(e)
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const saveHandler = async () => {
        const formdata = new FormData()
        formdata.append('email', store.user.email)
        formdata.append('name', name)
        formdata.append('surname', surname)
        formdata.append('dob', dob)
        formdata.append('city', city)
        formdata.append('username', username)
        await store.saveData(formdata)
    }

    const forgetHandler = (val) => {
        setForget(val)
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)

    }

    const emailHandler = (e) => {
        setNewEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setTextEmailError('Некорректный email')
            setEmailError(true)

        } else {
            setEmailError(false)
            setTextEmailError('')

        }
    }

    const newPasswordHandler = (e) => {
        setNewPassword(e.target.value)

        if (e.target.value.length < 6 || e.target.value.length > 32) {
            setNewPasswordError(true)
            setNPErrorText('Пароль должен быть больше 6 знаков')
        }
        if (e.target.value === '') {
            setNewPasswordError(false)
            setNPErrorText('')
        }
        if (e.target.value.length >= 6 && e.target.value.length < 32) {
            setNewPasswordError(false)
            setNPErrorText('')
        }
        if (e.target.value.length === 0) {
            setNewPasswordError(true)
            setNPErrorText('Обязательное поле')
        }
    }

    const retryNewPasswordHandler = (e) => {
        if (e.target.value !== newPassword) {
            setRetryNewPasswordError(true)
            setRetryNPErrorText('Пароли не совпадают')
        } else {
            setRetryNewPasswordError(false)
            setRetryNPErrorText('')
        }
    }

    const sendCodePasswordHandler = () => {
        store.sendChangePasswordCode(store.user.email)
        setSendCode(true)
    }

    const changeCodeHandler = (e) => {
        setChangeCode(e.target.value)
        if (e.target.value != store.user.changeCode) {
            setChangeCodeError(true)
            setChangeCodeTextError('Код неверный')
        }
        if (e.target.value == store.user.changeCode) {
            setChangeCodeError(false)
            setChangeCodeTextError('')
        }
        if (e.target.value.length === 0) {
            setChangeCodeError(true)
            setChangeCodeTextError('Обязательное поле')
        }
    }

    const completeChangeHandler = () => {
        if (forget === 'password') {
            store.changePassword(store.user.email, changeCode, newPassword)
            setForget('none')
            setSendCode(false)
        }
        if (forget === 'email') {
            store.changeEmail(store.user.email, newEmail, changeCode)
            setForget('none')
            setSendCode(false)
        }
    }

    return (
        <>
            <div className={(forget === 'password' || forget === 'email') ? `${styles.overlay}` : false}></div>
            <div className={styles.content}>
                <div className={styles.personalInfo}>
                    <p>Личная информация</p>
                    <div className={styles.inputs}>
                        <div className={styles.input}>
                            <p>Имя, Фамилия</p>
                            <input
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                        <div className={styles.input}>
                            <p>Юзернейм</p>
                            <input
                                type="text"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                            />
                        </div>
                        <div className={styles.input}>
                            <p>Почта</p>
                            <input
                                type="text"
                                value={email}
                                readOnly={true}
                            />
                        </div>
                        <div className={styles.input}>
                            <p>Город</p>
                            <input
                                type="text"
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            />
                        </div>
                        <div className={styles.input}>
                            <p>Дата рождения</p>
                            <input
                                onChange={(e) => setDob(e.target.value)}
                                value={dob}
                                type="date"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.secondBlock}>
                    <div className={styles.profilePhoto}>
                        <p>Фото профиля</p>
                        <div className={styles.photo}>
                            <img src={ava === null ? camera : process.env.REACT_APP_API_URL + ava} alt="" />
                            <div className={styles.imgButtons}>
                                <label className={styles.inputFiles}>
                                    <input
                                        type="file"
                                        id="img"
                                        onChange={(e) => uploadImageHandler(e)}
                                    />
                                    <span className={styles.inputButton} >
                                        <img src={camera} alt="" />
                                        Загрузить
                                    </span>
                                </label>
                                <button className={styles.deleteFile} onClick={deleteImageHandler}>
                                    <img src={trash} alt="" />
                                    Удалить
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.safety}>
                        <p>Безопасность</p>
                        <div className={styles.safetyButtons}>
                            <button onClick={() => forgetHandler('email')}>
                                Изменить почту
                            </button>
                            <button onClick={() => forgetHandler('password')}>
                                Изменить пароль
                            </button>
                        </div>
                    </div>
                </div>
                <button className={styles.saveButton} onClick={saveHandler}>
                    Сохранить изменения
                </button>
                {
                    (forget === 'password' || forget === 'email') &&
                    <div className={styles.changePass}>
                        {
                            (forget === 'password' && !sendCode) &&
                            <div className={styles.changeBlock}>
                                <p>Изменить пароль</p>
                                <div>
                                    <div className={styles.changeInputs}>
                                        <div>
                                            {
                                                newPasswordError &&
                                                <p>
                                                    {NPErrorText}
                                                    <img src={alert} alt="" />
                                                </p>
                                            }
                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={passwordIcon} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="password"
                                                        placeholder="Новый пароль"
                                                        onChange={(e) => newPasswordHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                retryNewPasswordError &&
                                                <p>
                                                    {retryNPErrorText}
                                                    <img src={alert} alt="" />
                                                </p>
                                            }
                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={passwordIcon} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="password"
                                                        placeholder="Повторите пароль"
                                                        onChange={(e) => retryNewPasswordHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={(!retryNewPasswordError && !newPasswordError) ? `${styles.saveChangeButton}` : `${styles.saveChangeButtonDisabled}`}
                                    disabled={(!retryNewPasswordError && !newPasswordError) ? false : true}
                                    onClick={sendCodePasswordHandler}
                                >
                                    Сохранить изменения
                                </button>
                            </div>
                        }
                        {
                            (forget === 'email' && !sendCode) &&
                            <div className={styles.changeBlockEmail}>
                                <p>Изменить почту</p>
                                <div>
                                    <div className={styles.changeInputs}>
                                        <div>
                                            {
                                                emailError &&
                                                <p>
                                                    {textEmailError}
                                                    <img src={alert} alt="" />
                                                </p>
                                            }
                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={mail2} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="email"
                                                        placeholder="Новая почта"
                                                        onChange={(e) => emailHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={!emailError ? `${styles.saveChangeButton}` : `${styles.saveChangeButtonDisabled}`}
                                    disabled={!emailError ? false : true}
                                    onClick={sendCodePasswordHandler}
                                >
                                    Сохранить изменения
                                </button>
                            </div>
                        }
                        {
                            sendCode &&
                            <div className={styles.changeBlock}>
                                <p>Сообщение с кодом отправленно на вашу почту</p>
                                <div>
                                    <div className={styles.changeInputs}>
                                        <div>
                                            {
                                                changeCodeError &&
                                                <p>
                                                    {changeCodeTextError}
                                                    <img src={alert} alt="" />
                                                </p>
                                            }
                                            <div className={styles.email}>
                                                <div className={styles.imgg}>
                                                    <img src={mail2} alt="" />
                                                </div>
                                                <div>
                                                    <input
                                                        className={styles.inputt}
                                                        type="email"
                                                        placeholder="Код"
                                                        onChange={(e) => changeCodeHandler(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={!changeCodeError ? `${styles.saveChangeButton}` : `${styles.saveChangeButtonDisabled}`}
                                    disabled={!changeCodeError ? false : true}
                                    onClick={completeChangeHandler}
                                >
                                    Готово
                                </button>
                            </div>
                        }
                    </div>
                }
            </div>
        </>
    )
}

export default observer(UserSettings)