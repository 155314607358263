import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/createdCourses.module.css'
import { observer } from "mobx-react-lite";
import check from '../Images/check.svg'
import feather from '../Images/feather.svg'
import archive from '../Images/archive.svg'
import arrowDown from '../Images/arrowDown.svg'
import arrowUp from '../Images/arrowUp.svg'
import edit from '../Images/edit.svg'
import CoursesService from "../service/CoursesService";
import { Context } from "..";
import { useNavigate } from "react-router-dom";
import { FREE_COURSE } from "../utils";

function CreatedCourses() {
    const { store, courseStore } = useContext(Context)
    const [courses, setCourses] = useState([])
    const [draftCourses, setDarftCourses] = useState([])
    const [publishedCourses, setPublishedCourses] = useState([])
    const [showCourses, setShowCourses] = useState('draft')
    const navigate = useNavigate()

    useEffect(() => {
        getCourses()
    }, [])

    useEffect(() => {
        const draftCourses = courses.filter(item => item.status === 'notpublished')
        setDarftCourses(draftCourses)

        const publishedCourses = courses.filter(item => item.status === 'published')
        setPublishedCourses(publishedCourses)
    }, [courses])

    async function getCourses() {
        try {
            const response = await CoursesService.fetchUserCourses(store.user.id)
            const dataArray = response.data.courses; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setCourses(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setCourses([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    const showCoursesHandler = (type) => {
        setShowCourses(type)
    }

    const createCourseHandler = async () => {
        await courseStore.createCourse(store.user.id)
        navigate(FREE_COURSE + `/${courseStore.course.id}`)
    }



    return (
        <>
            <div className={styles.content}>
                <div className={styles.courses}>
                    <p>Мои курсы</p>
                    <div className={styles.createdCourses}>
                        <button className={styles.createCourseButton} onClick={createCourseHandler}>
                            Создать новый курс
                        </button>
                        <p>Созданные ранее</p>
                        <button className={styles.typeCourse} onClick={() => showCoursesHandler('published')}>
                            <img src={check} alt="" className={styles.leftIcon} />
                            Опубликованные
                            <img src={showCourses === 'published' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button>
                        {
                            (showCourses === 'published' && publishedCourses.length !== 0) &&
                            <div className={styles.draftCourses}>
                                {

                                    publishedCourses.map((item, index) => (
                                        <>
                                            <div key={index} className={styles.courseBlock} onClick={() => navigate(FREE_COURSE + `/${item.id}`)}>
                                                <img src={process.env.REACT_APP_API_URL + item.img} alt="" className={styles.courseImg} />
                                                <p>{item.name}</p>
                                                <img src={edit} alt="" className={styles.rightIcon} />

                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                        <button className={styles.typeCourse} onClick={() => showCoursesHandler('draft')} >
                            <img src={feather} alt="" className={styles.leftIcon} />
                            Черновики
                            <img src={showCourses === 'draft' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button>
                        {
                            (showCourses === 'draft' && draftCourses.length !== 0) &&
                            <div className={styles.draftCourses}>
                                {

                                    draftCourses.map((item, index) => (
                                        <>
                                            <div key={index} className={styles.courseBlock} onClick={() => navigate(FREE_COURSE + `/${item.id}`)}>
                                                <img src={process.env.REACT_APP_API_URL + item.img} alt="" className={styles.courseImg} />
                                                <p>{item.name}</p>
                                                <img src={edit} alt="" className={styles.rightIcon} />

                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        }
                        {/* <button className={styles.typeCourse} onClick={() => showCoursesHandler('closed')} >
                            <img src={archive} alt="" className={styles.leftIcon} />
                            Закрытые
                            <img src={showCourses === 'closed' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                        </button> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default observer(CreatedCourses)