import $api from "../http";
import $apigo from "../http/indexGO";
import { AxiosResponse } from 'axios'
import { CourseResponse } from '../models/response/CoursesResponse'
import { ModuleResponse } from '../models/response/ModuleResponse'
import { ICourse } from "../models/ICourse";
import { IModule } from '../models/IModule'
import { ILesson } from "../models/ILesson";
import { LessonResponse } from "../models/response/LessonResponse";
import { ChapterResponse } from "../models/response/ChapterResponse";
import { IChapter } from "../models/IChapter";
import { MonthStatResponse } from "../models/response/MonthStatResponse";
import { YearIncomeStatResponse } from "../models/response/YearIncomeStatResponse";
import { IFavorites } from "../models/IFavorites";
import { ActiveCourseResponse } from "../models/response/ActiveCourseResponse";
import { DoneLessonsResponse } from "../models/response/DoneLessonsResponse";
import { IDoneLessons } from "../models/IDoneLessons";
import { DoneChaptersResponse } from "../models/response/DoneChaptersResponse";
import { IDoneChapters } from "../models/IDoneChapters";
import { IActiveCourse } from "../models/IActiveCourse";

export default class CoursesService {
    static async createCourse(userId: any): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/createCourse', { userId })
    }

    static async createModule(courseId: string): Promise<AxiosResponse<ModuleResponse>> {
        return $api.post<ModuleResponse>('/module/createModule', { courseId })
    }

    static async saveCourseData(formdata: any): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/savecoursedata', formdata)
    }

    static async getDoneCourses(user_id: string): Promise<AxiosResponse<ICourse[]>> {
        return $api.post<ICourse[]>('/courses/getDoneCourses', { user_id })
    }

    static async fetchUserCourses(userId: string): Promise<AxiosResponse<ICourse[]>> {
        return $api.post<ICourse[]>('/courses/fetchusercourses', { userId })
    }

    static async fetchUserCourse(courseId: string): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/refreshCourse', { courseId })
    }

    static async fetchCourseByType(typeId: any, additionaltype: any[]): Promise<AxiosResponse<CourseResponse>> {
        return $apigo.post<CourseResponse>('/get-courses-by-types', { type: typeId, additional_type: additionaltype })
    }

    static async fetchCourseModules(courseId: string): Promise<AxiosResponse<IModule[]>> {
        return $api.post<IModule[]>('/module/fetchcoursemodules', { courseId })
    }

    static async fetchCourseModule(moduleId: string): Promise<AxiosResponse<ModuleResponse>> {
        return $api.post<ModuleResponse>('/module/fetchcoursemodule', { moduleId })
    }

    static async fetchModuleLessons(moduleId: string): Promise<AxiosResponse<ILesson[]>> {
        return $api.post<ILesson[]>('/lesson/fetchmodulelessons', { moduleId, })
    }

    static async createLesson(moduleId: string, moduleNumber: any): Promise<AxiosResponse<ILesson>> {
        return $api.post<ILesson>('/lesson/createlesson', { moduleId, moduleNumber })
    }

    static async fetchLesson(lessonId: string): Promise<AxiosResponse<LessonResponse>> {
        return $api.post<LessonResponse>('/lesson/fetchlesson', { lessonId })
    }

    static async saveLesson(lessonId: string, name: string): Promise<AxiosResponse<LessonResponse>> {
        return $api.post<LessonResponse>('/lesson/savelesson', { lessonId, name })
    }

    static async refreshModule(moduleId: string): Promise<AxiosResponse<ModuleResponse>> {
        return $api.post<ModuleResponse>('/module/refreshModule', { moduleId })
    }

    static async refreshLesson(lessonId: string): Promise<AxiosResponse<LessonResponse>> {
        return $api.post<LessonResponse>('/lesson/savelesson', { lessonId })
    }

    static async saveModule(moduleId: string, name: string, description: string): Promise<AxiosResponse<ModuleResponse>> {
        return $api.post<ModuleResponse>('/module/saveModule', { moduleId, name, description })
    }

    static async deleteModule(moduleId: string): Promise<AxiosResponse<ModuleResponse>> {
        return $api.post<ModuleResponse>('/module/deleteModule', { moduleId })
    }

    static async createChapter(course_lesson_id: string, tag: string): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/createChapter', { course_lesson_id, tag })
    }

    static async fetchChapter(chapter_id: string): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/fetchChapter', { chapter_id })
    }

    static async fetchChapters(course_lesson_id: string): Promise<AxiosResponse<IChapter[]>> {
        return $api.post<IChapter[]>('/chapter/fetchChapters', { course_lesson_id })
    }

    static async saveChapter(formdata: any): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/saveChapter', formdata)
    }

    static async deleteLesson(lessonId: string): Promise<AxiosResponse<LessonResponse>> {
        return $api.post<LessonResponse>('/lesson/deleteLesson', { lessonId })
    }

    static async fetchMonthStat(course_info_id: string): Promise<AxiosResponse<MonthStatResponse>> {
        return $api.post<MonthStatResponse>('/courses/fetchMonthStat', { course_info_id })
    }

    static async fetchYearIncome(course_info_id: string): Promise<AxiosResponse<YearIncomeStatResponse>> {
        return $api.post<YearIncomeStatResponse>('/courses/fetchYearIncome', { course_info_id })
    }

    static async deleteCourse(course_info_id: string): Promise<AxiosResponse<string>> {
        return $api.post<string>('/courses/deleteCourse', { course_info_id })
    }

    static async publishCourse(course_info_id: string): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/publishCourse', { course_info_id })
    }

    static async fetchPublishedCourses(): Promise<AxiosResponse<ICourse[]>> {
        return $api.get<ICourse[]>('/courses/fetchPublishedCourses')
    }

    static async uploadCourseImage(formData: any): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/uploadCourseImage', formData)
    }

    static async deleteCourseImage(formData: any): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/deleteCourseImage', formData)
    }

    static async changeModuleName(formData: any): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/module/changeModuleName', formData)
    }

    static async addToFavorite(course_id: string, user_id: string): Promise<AxiosResponse<IFavorites[]>> {
        return $api.post<IFavorites[]>('/courses/addToFavorite', { course_id, user_id })
    }

    static async fetchAllFavorites(user_id: string): Promise<AxiosResponse<IFavorites[]>> {
        return $api.post<IFavorites[]>('/courses/fetchAllFavorites', { user_id })
    }

    static async uploadLessonImage(formData: any): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/uploadLessonImage', formData)
    }

    static async uploadLessonVideo(formData: any): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/uploadLessonVideo', formData)
    }

    static async deleteLessonVideo(formData: any): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/deleteLessonVideo', formData)
    }

    static async deleteChapter(chapter_id: string): Promise<AxiosResponse<ChapterResponse>> {
        return $api.post<ChapterResponse>('/chapter/deleteChapter', { chapter_id })
    }

    static async unPublishCourse(course_id: string): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/unPublishCourse', { course_id })
    }

    static async learnCourse(course_id: string, user_id: string): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/courses/learnCourse', { course_id, user_id })
    }

    static async fetchActiveCourses(user_id: string): Promise<AxiosResponse<ICourse[]>> {
        return $api.post<ICourse[]>('/courses/fetchActiveCourses', { user_id })
    }

    static async setCurrentLesson(user_id: string, course_id: string, lesson_id: string): Promise<AxiosResponse<ActiveCourseResponse>> {
        return $api.post<ActiveCourseResponse>('/courses/setCurrentLesson', { user_id, course_id, lesson_id })
    }

    static async setDoneChapter(user_id: string, course_id: string, lesson_id: string, chapter_id: string): Promise<AxiosResponse<DoneChaptersResponse>> {
        return $api.post<DoneChaptersResponse>('/chapter/setDoneChapter', { user_id, course_id, lesson_id, chapter_id })
    }

    static async getAllDoneChapters(user_id: string, lesson_id: string): Promise<AxiosResponse<IDoneChapters[]>> {
        return $api.post<IDoneChapters[]>('/chapter/getAllDoneChapters', { user_id, lesson_id })
    }

    static async setDoneLesson(user_id: string, course_id: string, lesson_id: string): Promise<AxiosResponse<DoneLessonsResponse>> {
        return $api.post<DoneLessonsResponse>('/lesson/setDoneLesson', { user_id, course_id, lesson_id })
    }

    static async getAllDoneLessons(user_id: string, course_id: string): Promise<AxiosResponse<IDoneLessons[]>> {
        return $api.post<IDoneLessons[]>('/lesson/getAllDoneLessons', { user_id, course_id })
    }

    static async fetchCurrentLesson(user_id: string, course_id: string): Promise<AxiosResponse<IActiveCourse>> {
        return $api.post<IActiveCourse>('/lesson/fetchCurrentLesson', { user_id, course_id })
    }

    static async getNextLesson(course_id: string, lesson_id: string): Promise<AxiosResponse<IActiveCourse>> {
        return $api.post<IActiveCourse>('/lesson/getNextLesson', { course_id, lesson_id })
    }

    static async addPeople(course_id: string): Promise<AxiosResponse<CourseResponse>> {
        return $api.post<CourseResponse>('/coirses/addPeople', { course_id })
    }
}

