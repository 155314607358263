import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import styles from '../styles/courseManagment.module.css'
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "..";
import HomePageManagment from "../components/HomePageManagment";
import StatsManagment from "../components/StatsManagment";
import ManagmentSettings from "../components/ManagmentSettings";
import MoneyStat from "../components/MoneyStat";
import { PERSONAL_PAGE } from "../utils";
import DocumentTitle from "react-document-title";

function CourseManagment() {
    const { store, courseStore } = useContext(Context)
    const params = useParams()
    const current = params.id
    const [menu, setMenu] = useState('home')
    const menuHandler = (v) => {
        setMenu(v)
    }

    const navigate = useNavigate()

    const deleteCourseHandler = () => {
        courseStore.deleteCourse(current)
        navigate(PERSONAL_PAGE + `/${store.user.id}`)
    }

    return (
        <>
            <DocumentTitle title={courseStore.course.name}>
                <div className={styles.container2}>
                    <div>
                        <Header />
                    </div>
                    {
                        current == courseStore.course.id &&
                        <div className={styles.conatainer}>
                            <div className={styles.content1}>
                                <div className={`${styles.navmenu} ${styles.glass}`}>
                                    <div className={styles.ava}>
                                        <div>
                                            <img className={styles.photo} src={"https://theta-learn.ru:3010/" + courseStore.course.img} alt="ava" />
                                        </div>
                                        <div className={styles.username}>
                                            {courseStore.course.name}
                                        </div>
                                    </div>
                                    <div className={styles.navpanel}>
                                        <label htmlFor="main">Основное</label>
                                        <div className={styles.main} id="main">
                                            <button onClick={() => menuHandler('home')}>Домашняя страница</button>
                                            <button onClick={() => menuHandler('stats')}>Статистика</button>
                                        </div>
                                        <label htmlFor="courses">Доходы</label>
                                        <div id="courses" className={styles.courses}>
                                            <button onClick={() => menuHandler('moneytStats')}>Статистика</button>
                                        </div>
                                        <label htmlFor="courses">Настройки</label>
                                        <div id="courses" className={styles.courses}>
                                            <button onClick={() => menuHandler('settings')}>Публикация</button>
                                            <button onClick={() => menuHandler('moneySettings')}>Цены</button>
                                        </div>
                                    </div>
                                    <button className={styles.deleteCourse} onClick={deleteCourseHandler}>
                                        Удалить курс
                                    </button>
                                </div>
                                <div className={`${styles.info} ${styles.glass}`}>
                                    <ShowInfo menu={menu} />
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>
        </>
    )
}

const ShowInfo = observer(({ menu, page }) => {

    return (
        <>
            {
                menu === 'home' &&
                <HomePageManagment />
            }
            {
                menu === 'stats' &&
                <StatsManagment />
            }
            {
                menu === 'settings' &&
                <ManagmentSettings />
            }
            {
                menu === 'moneytStats' &&
                <MoneyStat />
            }
        </>
    )
})
export default observer(CourseManagment)