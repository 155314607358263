import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/mainLearnCoursePage.module.css'
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Context } from "..";
import { useNavigate, useParams } from "react-router-dom";
import home from '../Images/home.svg'
import descript from '../Images/descript.svg'
import CoursesService from "../service/CoursesService";
import { COURSE_CARD, EDIT_LESSON, LEARN, LEARN_COURSE, LESSON_PAGE } from "../utils";
import DocumentTitle from "react-document-title";
import BurgerMenu from "../components/BurgerMenu";

function MainLearnCoursePage() {
    const navigate = useNavigate()

    const { store, courseStore } = useContext(Context)

    const params = useParams()
    const current = params.id

    const [ava, setAva] = useState()
    const [dopType, setDopType] = useState([])
    const [courseContent, setCourseContent] = useState([])
    const [panel, setPanel] = useState('program')
    const [modules, setModules] = useState([])
    const [showModule, setShowModule] = useState()
    const [currentLesson, setCurrentLesson] = useState('')

    useEffect(() => {
        getCourse()
        getModules()
        getCurrentLesson()
    }, [])

    async function getCourse() {
        try {
            await courseStore.fetchUserCourse(current)
            setDopType([...courseStore.course.additional_type])
            if (courseStore.course.course_content) {
                const parsedConent = JSON.parse(courseStore.course.course_content)
                setCourseContent(parsedConent)
            }

        } catch (e) {
            console.log(e)
        }
    }

    async function getModules() {
        try {
            const response = await CoursesService.fetchCourseModules(courseStore.course.id)
            const dataArray = response.data.modules; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setModules(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setModules([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    const navigateHandler = (id) => {
        navigate(COURSE_CARD + '/' + courseStore.course.id + LEARN + LESSON_PAGE + `/${id}`)
    }

    const panelHandler = (panel) => {
        setPanel(panel)
    }

    const showModuleHandler = (id) => {
        setShowModule(id)
    }

    const continueLearning = () => {
        navigate(COURSE_CARD + '/' + courseStore.course.id + LEARN + LESSON_PAGE + '/' + currentLesson.current_lesson)
    }

    async function getCurrentLesson() {
        try {
            const response = await CoursesService.fetchCurrentLesson(store.user.id, current)
            const coursesData = response.data.currentLesson
            setCurrentLesson(coursesData)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <DocumentTitle title={courseStore.course.name}>

                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <BurgerMenu />
                        <div className={styles.courseInfo}>
                            <div className={styles.navPanel}>
                                <p>{courseStore.course.name}</p>
                                <div className={styles.navButtons}>
                                    <button onClick={() => panelHandler('program')} className={panel === 'program' ? styles.selected : styles.navButton}>
                                        <img src={home} alt="" />
                                        Программа курса
                                    </button>
                                    <button onClick={() => panelHandler('description')} className={panel === 'description' ? styles.selected : styles.navButton}>
                                        <img src={descript} alt="" />
                                        Описание
                                    </button>
                                    <button className={styles.continueCourse} onClick={continueLearning}>
                                        Продолжить
                                    </button>
                                </div>
                            </div>
                            <div className={styles.info}>
                                {
                                    panel === 'program' &&

                                    <>
                                        <p>Программа курса</p>
                                        {
                                            modules.length !== 0 &&
                                            <div className={styles.modulesAndLessons}>
                                                {
                                                    modules.map((item, index) => (
                                                        <>
                                                            <div className={styles.module} key={index}>
                                                                <p>{item.name}</p>

                                                                <div className={styles.lessons}>
                                                                    <ListLessons id={item.id} navigateCB={navigateHandler} />
                                                                </div>

                                                            </div>
                                                        </>
                                                    ))

                                                }
                                            </div>
                                        }
                                    </>
                                }
                                {
                                    panel === 'description' &&
                                    <>
                                        <p>Описание курса</p>
                                        <div className={styles.description}>
                                            {
                                                courseStore.course.more_description
                                            }
                                        </div>
                                        <div className={styles.popts}>
                                            <p>В курсе</p>
                                            {
                                                courseContent.map((item, index) => (
                                                    <div key={item.number} className={styles.popt} id="popts">
                                                        <p>{index + 1}.</p>
                                                        <p>{item.description}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>

        </>
    )
}

function ListLessons({ id, navigateCB }) {
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        getLessons(id)
    }, [id])

    const navigateHandler = (id) => {
        navigateCB(id)
    }

    async function getLessons(id) {
        try {
            const response = await CoursesService.fetchModuleLessons(id)
            const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setLessons(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setLessons([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {
                lessons.map((item, index) => (
                    <>
                        <div className={styles.lesson} onClick={() => navigateHandler(item.id)}>
                            <p>
                                {item.name}
                            </p>
                        </div>
                    </>
                ))
            }
        </>
    )
}

export default observer(MainLearnCoursePage)