import { AxiosResponse } from "axios";
import $api from "../http";
import { IOfferResponse } from "../models/response/IOfferResponse";
import { IOffer } from "../models/IOffer";


export default class OfferService {
    static async createOffer(user_id: string): Promise<AxiosResponse<IOfferResponse>> {
        return $api.post<IOfferResponse>('/offer/createOffer', { user_id })
    }

    static async getOffer(offer_id: string): Promise<AxiosResponse<IOfferResponse>> {
        return $api.post<IOfferResponse>('/offer/getOffer', { offer_id })
    }

    static async getDraftOffers(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getDraftOffers', { user_id })
    }

    static async getDoneOffers(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getDoneOffers', { user_id })
    }

    static async getActiveOffers(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getActiveOffers', { user_id })
    }

    static async getInprogressOffers(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getInprogressOffers', { user_id })
    }

    static async getInprogressOffersIspolnitel(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getInprogressOffersIspolnitel', { user_id })
    }

    static async getDoneOffersIspolnitel(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getDoneOffersIspolnitel', { user_id })
    }

    static async getFavoriteOffersIspolnitel(user_id: string): Promise<AxiosResponse<IOffer[]>> {
        return $api.post<IOffer[]>('/offer/getFavoriteOffersIspolnitel', { user_id })
    }

    static async saveOfferData(formdata: any): Promise<AxiosResponse<IOfferResponse>> {
        return $api.post<IOfferResponse>('/offer/saveOfferData', formdata)
    }

    static async getAllOffers(): Promise<AxiosResponse<IOffer[]>> {
        return $api.get<IOffer[]>('/offer/getAllOffers')
    }

    static async addToFavorite(user_id: string, offer_id: string): Promise<AxiosResponse<IOfferResponse>> {
        return $api.post<IOfferResponse>('/offer/addToFavorite', { user_id, offer_id })
    }
}