import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styles from "../styles/profileSettings.module.css"
import { Context } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer"
import user2 from '../Images/user2.svg'
import zap from '../Images/zap.svg'
import book from '../Images/book.svg'
import briefcase from '../Images/briefcase.svg'
import UserSettings from "../components/UserSettings";
import CreatedCourses from "../components/CreatedCourses";
import MyActiveCourse from "../components/MyActiveCourse";
import DocumentTitle from "react-document-title";
import BurgerMenu from "../components/BurgerMenu";
import freelancePanel from '../Images/freelancePanel.svg'
import MyOffers from "../components/MyOffers";

function ProfileSettings() {
    const location = useLocation()
    const { from } = location.state || {}
    useEffect(() => {
        if (from === '/burgerMenu') {
            setPanel('created')
        }
    }, [])
    const { store } = useContext(Context)
    const [panel, setPanel] = useState('settings')
    const navigate = useNavigate()


    const panelHandler = (panel) => {
        setPanel(panel)
    }


    const params = useParams()
    const current = params.id

    return (
        <>
            <DocumentTitle title='Настройки'>
                <div className={`${styles.container}`}>

                    <div>
                        <Header />
                    </div>

                    <div className={styles.content}>
                        <BurgerMenu />
                        {
                            current == store.user.id &&
                            <>
                                <div className={styles.settings}>
                                    <div className={styles.navPanel}>
                                        <p>Настройки</p>
                                        <div className={styles.navButtons}>
                                            <button onClick={() => panelHandler('settings')} className={panel === 'settings' ? `${styles.selected}` : false}>
                                                <img src={user2} alt="" />
                                                <p>Профиль</p>
                                            </button>
                                            {/* <button onClick={() => panelHandler('interes')} className={panel === 'interes' ? `${styles.selected}` : false}>
                                                <img src={zap} alt="" />
                                                <p>Мои интересы</p>
                                            </button> */}
                                            <button onClick={() => panelHandler('learning')} className={panel === 'learning' ? `${styles.selected}` : false}>
                                                <img src={book} alt="" />
                                                <p>Мое обучение</p>
                                            </button>
                                            <button onClick={() => panelHandler('created')} className={panel === 'created' ? `${styles.selected}` : false}>
                                                <img src={briefcase} alt="" />
                                                <p>Мои созданные курсы</p>
                                            </button>
                                            <button onClick={() => panelHandler('offers')} className={panel === 'offers' ? `${styles.selected}` : false}>
                                                <img src={freelancePanel} alt="" />
                                                <p>Мои заказы</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.info}>
                                        {
                                            panel === 'settings' &&
                                            <UserSettings />
                                        }
                                        {
                                            panel === 'learning' &&
                                            <MyActiveCourse />
                                        }
                                        {
                                            panel === 'created' &&
                                            <CreatedCourses />
                                        }
                                        {
                                            panel === 'offers' &&
                                            <MyOffers />
                                        }
                                    </div>
                                </div>
                            </>
                        }
                        {
                            !store.isAuth &&
                            <>
                                <div className={styles.notAuth}>
                                    Авторизуйтесь
                                </div>
                            </>

                        }
                    </div>

                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>

        </>
    )
}


export default observer(ProfileSettings)