import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/mainCoursePage.module.css'
import { observer } from "mobx-react-lite";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Context } from "..";
import { useNavigate, useParams } from "react-router-dom";
import clock from '../Images/clock.svg'
import time from '../Images/time.svg'
import heart from '../Images/heart.svg'
import check from '../Images/check.svg'
import CoursesService from "../service/CoursesService";
import { toast } from "react-toastify";
import { icons } from "../data/coursesData";
import { LEARN_COURSE, LEARN_MAIN_PAGE, LOGIN_PAGE } from "../utils";
import DocumentTitle from "react-document-title";
import redHeart from '../Images/redHeart.svg'
import BurgerMenu from "../components/BurgerMenu";

function MainCoursePage() {
    const { courseStore, store } = useContext(Context)
    const params = useParams()
    const current = params.id

    const navigate = useNavigate()

    const [name, setName] = useState(courseStore.course.name)
    const [ava, setAva] = useState(null)
    const [aboutCourse, setAboutCourse] = useState(courseStore.course.more_description)
    const [description, setDescription] = useState(courseStore.course.description)
    const [courseContent, setCourseContent] = useState([])
    const [type, setType] = useState('1')
    const [dopType, setDopType] = useState([])
    const [modules, setModules] = useState([])
    const [courses, setCourses] = useState([])
    const [favoriteCourses, setFavoriteCourses] = useState([])

    useEffect(() => {
        getCourse()
        getModules()
        getCourses()
        GetFavoriteCourses()
    }, [])


    async function getCourse() {
        try {
            await courseStore.fetchUserCourse(current)
            setAva(courseStore.course.img)
            setDopType([...courseStore.course.additional_type])
            if (courseStore.course.course_content) {
                const parsedConent = JSON.parse(courseStore.course.course_content)
                setCourseContent(parsedConent)
            }

        } catch (e) {
            console.log(e)
        }
    }

    async function getCourses() {
        try {
            const response = await CoursesService.fetchActiveCourses(localStorage.getItem('userId'))
            const coursesData = response.data.courses
            if (Array.isArray(coursesData)) {
                setCourses(coursesData)
            }
        } catch (e) {
            setCourses([])
            console.log(e)
        }
    }


    async function getModules() {
        try {
            const response = await CoursesService.fetchCourseModules(current)
            const dataArray = response.data.modules; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setModules(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setModules([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    const addToFavoriteHandler = async () => {
        try {
            const response = await CoursesService.addToFavorite(current, store.user.id)
            toast.success('Успех', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            console.log(e)
        }
        GetFavoriteCourses()

    }

    const learnCourseHandler = () => {
        try {
            CoursesService.learnCourse(current, store.user.id)
            navigate(LEARN_MAIN_PAGE + '/' + current)
            toast.success('Успех', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const continueLearning = () => {
        navigate(LEARN_MAIN_PAGE + '/' + current)

    }

    async function GetFavoriteCourses() {
        try {
            const response = await CoursesService.fetchAllFavorites(localStorage.getItem('userId'))
            const coursesArray = response.data.courses
            if (Array.isArray(coursesArray)) {
                setFavoriteCourses(coursesArray)
            }
            else {
                setFavoriteCourses([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const navigateHandler = () => {
        navigate(LOGIN_PAGE)
    }

    return (
        <>
            <DocumentTitle title={courseStore.course.name}>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={styles.content}>
                        <BurgerMenu />

                        <div className={styles.mainBlock}>
                            <div className={styles.info}>
                                <div className={styles.img}>
                                    <img src={process.env.REACT_APP_API_URL + ava} alt="" />
                                    <div className={styles.timeRating}>
                                        <div>
                                            <img src={clock} alt="" />
                                            <p>25</p>
                                        </div>
                                        {/* <div>
                                            <img src={time} alt="" />
                                            <p>--</p>
                                        </div> */}
                                    </div>
                                    {
                                        !store.isAuth &&
                                        <button className={styles.addToFavorite} onClick={navigateHandler}>
                                            <>
                                                Авторизоваться
                                            </>
                                        </button>
                                    }
                                    {
                                        store.isAuth &&
                                        <button className={styles.addToFavorite} onClick={() => addToFavoriteHandler(current)}>
                                            {
                                                !favoriteCourses.some(i => i.id == current) &&
                                                <>
                                                    < img src={heart} alt="" />
                                                    Сохранить
                                                </>

                                            }

                                            {
                                                favoriteCourses.some(i => i.id == current) &&
                                                <>
                                                    < img src={redHeart} alt="" />
                                                    Удалить
                                                </>
                                            }
                                        </button>
                                    }
                                    {
                                        store.isAuth &&
                                        <>
                                            {
                                                !courses.some(i => i.id == current) &&
                                                <button className={styles.buy} onClick={learnCourseHandler}>
                                                    {
                                                        courseStore.course.price === '0' ? 'Учиться' : 'Купить'
                                                    }

                                                </button>
                                            }
                                        </>
                                    }
                                    {
                                        store.isAuth &&
                                        <>
                                            {
                                                courses.some(i => i.id == current) &&
                                                <button className={styles.buy} onClick={continueLearning}>

                                                    {
                                                        courses.some(i => i.id == current) &&
                                                        <>
                                                            К курсу
                                                        </>
                                                    }
                                                </button>
                                            }
                                        </>
                                    }
                                </div>
                                <div className={styles.courseContent}>
                                    <div className={styles.price}>
                                        <p className={styles.courseName}>{courseStore.course.name}</p>
                                        <p className={styles.pricee}>{courseStore.course.price === '0' ? 'Бесплатно' : courseStore.course.price}</p>
                                    </div>
                                    <div className={styles.mainInfo}>
                                        <div className={styles.inCourse}>
                                            <p>В курсе</p>
                                            {
                                                dopType.length !== 0 &&
                                                <div className={styles.tags}>
                                                    {
                                                        icons.filter(item => dopType.includes(item.id)).map(item => (
                                                            <div key={item.id} className={styles.tag}>
                                                                {item.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.moreDescription}>
                                            <p>О курсе</p>
                                            <p>{courseStore.course.more_description}</p>
                                        </div>
                                    </div>
                                    <div className={styles.uWillLearn}>
                                        <p>Вы научитесь</p>
                                        {
                                            courseContent.length !== 0 &&
                                            <div className={styles.coursePoints}>
                                                {
                                                    courseContent.map((item, index) => (
                                                        <div>
                                                            <img src={check} alt="" />
                                                            {item.description}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.courseProgram}>
                                        <p>Программа курса</p>
                                        {
                                            modules.map((item, index) => (
                                                <>
                                                    <div className={styles.module} key={index}>
                                                        <p>
                                                            {item.name}
                                                        </p>
                                                        <div className={styles.lessons}>
                                                            <ListLessons id={item.id} />
                                                        </div>
                                                    </div>
                                                </>
                                            ))

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>
        </>
    )
}

function ListLessons({ id }) {
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        getLessons(id)
    }, [id])

    async function getLessons(id) {
        try {
            const response = await CoursesService.fetchModuleLessons(id)
            const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setLessons(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setLessons([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {
                lessons.map((item, index) => (
                    <>
                        <div className={styles.lesson}>
                            <p>
                                {item.name}
                            </p>
                        </div>
                    </>
                ))
            }
        </>
    )
}

export default observer(MainCoursePage)