import React, { useContext, useEffect } from 'react'
import styles from "../styles/header.module.css"
import { Context } from "..";
import { observer } from "mobx-react-lite";

import { useState } from 'react'
import {
    useNavigate,
} from "react-router-dom";
import { CATALOG_ROUTE, FAVORITE_COURSES, LANDING_ROUTE, LOGIN_PAGE, SETTINGS_ROUTE, SIGNUP_PAGE, } from '../utils';
import bell from '../Images/bell.svg'
import user1 from '../Images/user1.svg'
import arrowDown from '../Images/arrowDown.svg'
import settings from '../Images/settings.svg'
import moon from '../Images/moon.svg'
import logout from '../Images/logout.svg'


function Header() {
    const { store } = useContext(Context)
    const navigate = useNavigate()
    const [showMenu, setShowMenu] = useState(false)
    const [showBurger, setShowBurger] = useState(false)

    const navigateHandler = (path) => {
        if (path === 'vhod') {
            navigate(LOGIN_PAGE)
        }
        if (path === 'reg') {
            navigate(SIGNUP_PAGE)
        }
        if (path === 'settings') {
            navigate(SETTINGS_ROUTE + `/${store.user.id}`)
        }
        if (path === 'landing') {
            navigate(LANDING_ROUTE)
        }
        if (path === 'catalog') {
            navigate(CATALOG_ROUTE)
        }
        if (path === 'favorite') {
            navigate(FAVORITE_COURSES + '/' + store.user.id)
        }
    }

    const showMenuHandler = (show) => {
        setShowMenu(show ? false : true)
    }

    const logoutHandler = () => {
        store.logout()
        navigate(CATALOG_ROUTE)
        setShowMenu(false)
    }

    return (
        <>

            <div className={styles.container}>
                <div>
                    <div className={styles.lefBlock}>
                        {/* <div className={styles.menuButton} onClick={() => showBurgerHandler()}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> */}
                        <button className={styles.logo} onClick={() => navigateHandler('landing')}>
                            Theta
                        </button>
                    </div>
                    <div className={styles.middleBlock}>
                        {/* <button>Учиться</button>
                        <button>Преподавать</button>
                        <button>Тренироваться</button> */}
                    </div>
                    <div className={styles.rightBlock}>
                        <div className={styles.authButtons}>
                            {
                                !store.isAuth &&
                                <>
                                    <button className={styles.vhod} onClick={() => navigateHandler('vhod')}>Вход</button>
                                    <button className={styles.reg} onClick={() => navigateHandler('reg')}>Регистрация</button>
                                </>
                            }
                            {
                                store.isAuth &&
                                <>
                                    <div className={styles.userBlock}>
                                        {/* <img src={bell} alt="" /> */}
                                        <div className={styles.profile} onClick={() => showMenuHandler(showMenu)}>
                                            <div className={styles.userPhoto}>
                                                <img src={store.user.img !== null ? process.env.REACT_APP_API_URL + store.user.img : user1} alt="" />
                                            </div>
                                            <img src={arrowDown} alt="" />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {
                        showMenu &&
                        <div className={styles.dropDawnMenu}>
                            <div className={styles.userInfo}>
                                <div className={styles.userPhoto}>
                                    <img src={store.user.img !== null ? process.env.REACT_APP_API_URL + store.user.img : user1} alt="" />
                                </div>
                                <div className={styles.name}>
                                    <p className={styles.fio}>{store.user.name}</p>
                                    <p className={styles.username}>{store.user.username}</p>
                                </div>
                            </div>
                            <div className={styles.settButtons}>
                                <div className={styles.settings} onClick={() => navigateHandler('settings')}>
                                    <img src={settings} alt="" />
                                    <p>Настройки</p>
                                </div>
                                {/* <div className={styles.settings}>
                                    <img src={moon} alt="" />
                                    <p>Темная тема</p>
                                </div> */}
                            </div>
                            <div className={styles.exit}>
                                <div className={styles.settings} onClick={logoutHandler}>
                                    <img src={logout} alt="" />
                                    <p>Выйти</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </>
    )

}

export default observer(Header)