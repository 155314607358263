import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from '../styles/editLesson.module.css'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import { Context } from "..";
import CoursesService from "../service/CoursesService";
import { toast } from "react-toastify";


function VideoEditor({ chapter, onTextHandler, id }) {
    const [textContent, setTextContent] = useState(chapter)
    const [video, setVideo] = useState('')
    const quillRef = useRef(null);
    const { courseStore } = useContext(Context)

    useEffect(() => {
        onTextHandler(textContent)
    }, [textContent])

    useEffect(() => {
        getChapter()
    }, [])

    async function getChapter() {
        try {
            await courseStore.fetchChapter(id)
            setVideo(courseStore.chapter.video)
        } catch (e) {
            console.log(e)
        }
    }

    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            if (input.files && input.files[0]) {
                const file = input.files[0];
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                const savedRange = range ? range.index : 0;
                const formData = new FormData();
                formData.append('img', file);
                formData.append('pos', savedRange);
                formData.append('chapter_id', id);
                try {
                    const response = await CoursesService.uploadLessonImage(formData)
                    const chaptData = response.data.chapter.imgs
                    const chaptImg = JSON.parse(chaptData)[0].fileName
                    const imgPos = JSON.parse(chaptData)[0].pos
                    toast.success('Изображение загружено', {
                        position: "top-center",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });

                    if (quillRef.current) {
                        const range = quillRef.current.getEditor().getSelection(true);
                        quillRef.current.getEditor().insertEmbed(imgPos, 'image', process.env.REACT_APP_API_URL + chaptImg);
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                    toast.error('Ошибка, повторите позже', {
                        position: "top-center",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    });
                }
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image', 'code-block'],
                ['clean']
            ],
            handlers: {
                'image': handleImageUpload
            }
        }
    }), [])

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'code-block'
    ];

    const handleChangeText = (value) => {
        changeInfo('description', value, chapter.number);
    };

    const changeInfo = (key, value, number) => {
        setTextContent({ ...chapter, [key]: value })

    }

    const uploadVideoHandler = async (e) => {
        const video = e.target.files[0]
        const formData = new FormData()
        formData.append('chapter_id', id)
        formData.append('video', video)
        try {
            const response = await CoursesService.uploadLessonVideo(formData)
            const videoName = (await response).data.chapter.video
            getChapter()
            setVideo(process.env.REACT_APP_API_URL + `${videoName}`)
        } catch (e) {
            console.log(e)
        }
    }

    const deleteVideoHandler = async () => {
        const formData = new FormData()
        formData.append('chapter_id', id)
        try {
            await CoursesService.deleteLessonVideo(formData)
            getChapter()
            setVideo('')
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <div className={styles.videoEditor}>
                <div className={styles.videoFile}>
                    {
                        (video === 'video' || video === '') &&
                        <div className={styles.imgfield}>
                            <label className={styles.inputFiles}>
                                <input
                                    type="file"
                                    id="img"
                                    onChange={(e) => uploadVideoHandler(e)}
                                />
                                <span className={styles.inputButton}>Добавить видео</span>
                            </label>
                        </div>
                    }
                    {
                        (video !== 'video' && video !== '') &&
                        <div className={styles.imgfield}>
                            <button className={styles.inputButton} onClick={deleteVideoHandler}>Удалить видео</button>
                        </div>
                    }
                    {
                        (video !== 'video' && video !== '') &&
                        <div className={styles.videoField}>
                            <video width="820" controls
                                src={process.env.REACT_APP_API_URL + `${video}`}></video>
                        </div>
                    }
                </div>

                <div className={styles.textEditor}>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={textContent.description}
                        onChange={handleChangeText}
                        formats={formats}
                        modules={modules}
                    />
                </div>
            </div >
        </>
    )
}

export default observer(VideoEditor)