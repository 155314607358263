import { makeAutoObservable } from "mobx";
import { ICourse } from "../models/ICourse";
import { IModule } from '../models/IModule'
import axios from 'axios'
import { APi_URL } from "../http";
import CoursesService from "../service/CoursesService";
import { CourseResponse } from "../models/response/CoursesResponse";
import { ILesson } from "../models/ILesson";
import { IChapter } from "../models/IChapter";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default class CourseStore {
    module = {} as IModule
    course = {} as ICourse
    data = {} as ICourse;
    lesson = {} as ILesson
    chapter = {} as IChapter

    constructor() {
        makeAutoObservable(this)
    }
    setCourse(course: ICourse) {
        this.course = course
    }

    setData(data: ICourse) {
        this.data = data
    }

    setModule(module: IModule) {
        this.module = module
    }

    setLesson(lesson: ILesson) {
        this.lesson = lesson
    }

    setChapter(chapter: IChapter) {
        this.chapter = chapter
    }

    async createCourse(userId: string) {
        try {
            const response = await CoursesService.createCourse(userId)
            localStorage.setItem('courseId', response.data.course.id)
            this.setCourse(response.data.course)
            toast.success('Курс создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async saveCourseData(formdata: any) {
        try {
            const response = await CoursesService.saveCourseData(formdata)
            localStorage.setItem('courseId', response.data.course.id)
            this.setCourse(response.data.course)
            toast.success('Данные сохранены!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async createModule(courseId: string) {
        try {
            const response = await CoursesService.createModule(courseId)
            this.setModule(response.data.module)
            toast.success('Модуль создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async checkCourse(courseId: string) {
        try {
            const response = await axios.post<CourseResponse>(`${APi_URL}/courses/refreshCourse`, { courseId }, { withCredentials: true })
            localStorage.setItem('courseId', response.data.course.id)
            this.setCourse(response.data.course)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async refresshModule(moduleId: string) {
        try {
            const response = await CoursesService.refreshModule(moduleId)
            this.setModule(response.data.module)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async fetchUserCourses(userId: string) {
        try {
            const response = await CoursesService.fetchUserCourses(userId)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async fetchUserCourse(course_id: string) {
        try {
            const response = await CoursesService.fetchUserCourse(course_id)
            localStorage.setItem('courseId', response.data.course.id)
            this.setCourse(response.data.course)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async fetchCourseByType(course_id: any) {
        try {
            const additionaltype = new Array()
            const response = await CoursesService.fetchCourseByType(course_id, additionaltype)
            this.setCourse(response.data.course)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async fetchCourseModules(courseId: string) {
        try {
            const response = await CoursesService.fetchCourseModules(courseId)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async fetchCourseModule(moduleId: string) {
        try {
            const response = await CoursesService.fetchCourseModule(moduleId)
            this.setModule(response.data.module)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async fetchModuleLessons(moduleId: string) {
        try {
            const response = await CoursesService.fetchModuleLessons(moduleId)
        } catch (e) {
            console.log(e.response?.data?.message)
        }

    }

    async createLesson(moduleId: string, moduleNumber: any) {
        try {
            const response = await CoursesService.createLesson(moduleId, moduleNumber)
            toast.success('Урок создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }

    async fetchLesson(lessonId: string) {
        try {
            const response = await CoursesService.fetchLesson(lessonId)
            this.setLesson(response.data.lesson)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async saveLesson(lessonId: string, name: string, content: string) {
        try {
            const response = await CoursesService.saveLesson(lessonId, name)
            this.setLesson(response.data.lesson)
            toast.success('Урок сохранен!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async saveModule(moduleId: string, name: string, description: string) {
        try {
            const response = await CoursesService.saveModule(moduleId, name, description)
            toast.success('Данные сохранены!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            this.setModule(response.data.module)
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async deleteModule(moduleId: string) {
        try {
            const response = await CoursesService.deleteModule(moduleId)
            this.setModule(response.data.module)
            toast.success('Модуль удален!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async createChapter(course_lesson_id: string, tag: string) {
        try {
            const response = await CoursesService.createChapter(course_lesson_id, tag)
            toast.success('Раздел создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            this.setChapter(response.data.chapter)
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async saveChapter(formdata: any) {
        try {
            const response = await CoursesService.saveChapter(formdata)
            this.setChapter(response.data.chapter)
            toast.success('Глава сохранена!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async fetchChapter(chapter_id: string) {
        try {
            const response = await CoursesService.fetchChapter(chapter_id)
            localStorage.setItem('chapterId', response.data.chapter.id)
            this.setChapter(response.data.chapter)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async deleteLesson(lessonId: string) {
        try {
            const response = await CoursesService.deleteLesson(lessonId)
            toast.success('Урок удален!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async fetchMonthStat(course_info_id: string) {
        try {
            const response = await CoursesService.fetchMonthStat(course_info_id)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async fetchYearIncome(course_info_id: string) {
        try {
            const response = await CoursesService.fetchYearIncome(course_info_id)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async deleteCourse(course_info_id: string) {
        try {
            const response = await CoursesService.deleteCourse(course_info_id)
            toast.success('Курс удален!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async publishCourse(course_info_id: string) {
        try {
            const response = await CoursesService.publishCourse(course_info_id)
            toast.success('Курс опубликован!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async fetchPublishedCourses() {
        try {
            const response = CoursesService.fetchPublishedCourses()

        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }
}