import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styles from '../styles/TrainerPage.module.css'
import { Context } from "..";
import { useNavigate } from "react-router-dom";
import { CATALOG_TRAINERS, CREATE_TRAINER, MY_TRAINERS } from "../utils";
import TrainerService from "../service/TrainerService";
import DocumentTitle from "react-document-title";
function TrainerPage() {
    const [trainers, setTrainers] = useState([])
    const [showProfile, setsetShowProfile] = useState(false)

    const { store, trainerStore } = useContext(Context)
    let progress = (trainerStore.userTrainer.points / trainerStore.userTrainer.status_value) * 100
    useEffect(() => {
        getTrainerUserInfo()
    }, [])

    async function getTrainerUserInfo() {
        try {
            await trainerStore.fetchUserTrainerInfo(localStorage.getItem('userId'))
        } catch (e) {
            console.log(e)
        }

    }

    useEffect(() => {
        getChapters()
    }, [])

    async function getChapters() {
        try {
            const response = await TrainerService.fetchAllTrainers()
            const dataArray = response.data.trainers; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setTrainers(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setTrainers([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    const showProfileHandler = (vv) =>{
        setsetShowProfile(vv ? false : true)
    }

    const navigate = useNavigate()

    const navigateHandler = (path) => {
        if (path === 'catalog') {
            navigate(CATALOG_TRAINERS)
        }
        if (path === 'create') {
            navigate(CREATE_TRAINER + '/' + store.user.id)
        }
        if (path === 'mytrainers') {
            navigate(MY_TRAINERS + '/' + store.user.id)
        }
    }

    return (
        <DocumentTitle title="Тренажёры">
        <>
            <div className={styles.container}>
                <div>
                    <Header />
                </div>
                <div className={styles.content}>
                    <div className={`${styles.profileBar} ${styles.glass}`}>                     
                        {
                            store.isAuth &&
                            <div className={styles.userInfo}>
                                <div className={styles.fio}>
                                    <p>{store.user.name} {store.user.surname}</p>
                                </div>
                                <div className={styles.mainUserInfo}>
                                    <div>
                                        <div>
                                            Email
                                        </div>
                                        <div>
                                            Test
                                        </div>
                                        <div>
                                            Points
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {store.user.email}
                                        </div>
                                        <div>
                                            Название теста
                                        </div>
                                        <div>
                                            {trainerStore.userTrainer.points}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.progress}>
                                    <p>Прогресс</p>
                                    <div className={styles.progressInfo}>
                                        <div className={styles.values}>
                                            <div>
                                                {
                                                    trainerStore.userTrainer.status_value === 300 &&
                                                    0
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 600 &&
                                                    300
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 1000 &&
                                                    600
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 2000 &&
                                                    1000
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 5000 &&
                                                    2000
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 10000 &&
                                                    5000
                                                }
                                                {
                                                    trainerStore.userTrainer.status_value === 25000 &&
                                                    10000
                                                }
                                            </div>
                                            <div>
                                                {trainerStore.userTrainer.points}
                                            </div>
                                            <div>
                                                {trainerStore.userTrainer.status_value}
                                            </div>
                                        </div>
                                        <div className={styles.progressBar}>
                                            <div className={styles.progressbarfill} style={{ width: `${progress}%` }}></div>
                                        </div>
                                        <div className={styles.userStatus}>
                                            {trainerStore.userTrainer.status}
                                        </div>
                                        <div>
                                            <button className={styles.seeAllStatuses}>Посмотреть все звания</button>
                                        </div>
                                    </div>
                                </div>


                                <div className={styles.createMy}>
                                    <button className={styles.myTrainers} onClick={() => navigateHandler('mytrainers')}>
                                        Мои тренажеры
                                    </button>
                                    {
                                        store.user.role === 'ADMIN' &&
                                        <button className={styles.myTrainers} onClick={() => navigateHandler('create')}>
                                            Создать
                                        </button>
                                    }
                                </div>

                                {/* <div className={styles.doneTrainers}>
                                    <p>Недавние</p>

                                </div> */}
                            </div>
                        }
                        {
                            !store.isAuth &&
                            <div>
                                Авторизуйтесь
                            </div>
                        }
                    </div>
                    <div className={`${styles.trainersCatalog}`}>
                        <div className={styles.allTrainers}>
                            <button className={`${styles.trainerCatalog} ${styles.glass}`} onClick={() => navigateHandler('catalog')}>
                                <p>Все задачи</p>
                            </button>
                        </div>
                        <div>
                            <button className={`${styles.langTrainers} ${styles.glass}`} onClick={() => navigateHandler('catalog')}>
                                <p>JavaScript</p>
                            </button>
                        </div>
                    </div>
                </div>


                <div className={styles.contentMobile}>
                    <button className={`${styles.showProfileMobile} ${styles.glass}`} onClick={() => showProfileHandler(showProfile)}>Показать профиль</button>
                    <div>
                        {
                            showProfile && 
                            <div className={`${styles.userInfoMobile} ${styles.glass}`}>
                                {
                                    store.isAuth &&
                                    <>
                                        <div>
                                            {store.user.name} {store.user.surname}
                                        </div>
                                        <div>
                                            {store.user.email}
                                        </div>
                                        <div className={styles.progressMobile}>
                                            <p>Прогресс</p>
                                            <div className={styles.progressInfo}>
                                                <div className={styles.values}>
                                                    <div>
                                                        {
                                                            trainerStore.userTrainer.status_value === 300 &&
                                                            0
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 600 &&
                                                            300
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 1000 &&
                                                            600
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 2000 &&
                                                            1000
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 5000 &&
                                                            2000
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 10000 &&
                                                            5000
                                                        }
                                                        {
                                                            trainerStore.userTrainer.status_value === 25000 &&
                                                            10000
                                                        }
                                                    </div>
                                                <div>
                                                {trainerStore.userTrainer.points}
                                            </div>
                                            <div>
                                                {trainerStore.userTrainer.status_value}
                                            </div>
                                        </div>
                                        <div className={styles.progressBar}>
                                            <div className={styles.progressbarfill} style={{ width: `${progress}%` }}></div>
                                        </div>
                                        <div className={styles.userStatus}>
                                            {trainerStore.userTrainer.status}
                                        </div>
                                        <div>
                                            <button className={styles.seeAllStatuses}>Посмотреть все звания</button>
                                        </div>
                                    </div>
                                        
                                        </div>
                                    </>
                                }
                                {
                                    !store.isAuth &&
                                    <button>
                                        Авторизуйтесь
                                    </button>
                                }
                            </div>
                        }
                        
                    </div>
                    <div>
                    {
                        trainers.length !== 0 &&
                        <div className={`${styles.catalog} `}>
                            <p className={styles.zagolovok}>Задачи</p>

                            {

                                trainers.map((item, index) => (
                                    <button key={index} className={`${styles.trainerBlock} ${styles.glass2}`} onClick={() => navigateHandler(item.id)}>
                                        <div className={styles.mainInfo}>
                                            <div className={styles.name}>
                                                {item.name}
                                            </div>
                                            {
                                                (item.dificult === '1 Xi' || item.dificult === '2 Xi' || item.dificult === '3 Xi' || item.dificult === '4 Xi') &&
                                                <div className={(item.dificult === '1 Xi' || item.dificult === '2 Xi') ? `${styles.ezLevel}` : `${styles.medLevel}`}>
                                                    <h2 >{item.dificult}</h2>
                                                </div>
                                            }
                                            {
                                                (item.dificult === '5 Xi' || item.dificult === '6 Xi') &&
                                                <div className={(item.dificult === '5 Xi' || item.dificult === '6 Xi') ? `${styles.hardLevel}` : false}>
                                                    <h2>{item.dificult}</h2>
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.progLang}>
                                            {item.programming_languages}
                                        </div>
                                    </button>
                                ))

                            }
                        </div>
                    }
                    </div>
                </div>


                <div>
                    <Footer />
                </div>
            </div >
        </>
        </DocumentTitle>
    )
}

export default observer(TrainerPage)