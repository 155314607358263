import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/CoursesContentPage.module.css'
import DocumentTitle from "react-document-title";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import CoursesService from "../service/CoursesService";
import { Context } from "..";
import { EditorView } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import { aura } from "@uiw/codemirror-themes-all";
import { langs } from "@uiw/codemirror-extensions-langs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import arrowLeft from '../Images/arrowLeft.svg'
import styled from "styled-components";
import { COURSE_CARD, LEARN, LEARN_MAIN_PAGE, LESSON_PAGE } from "../utils";
import checkTest from '../Images/checkTest.svg'
import galochka from '../Images/galochka.svg'
import BurgerMenu from "../components/BurgerMenu";

function CoursesContentPage() {
    const [contentList, setContentList] = useState([])
    const [types, setTypes] = useState([])
    const [lessons, setLessons] = useState([])
    const [modules, setModules] = useState([])
    const { store, courseStore } = useContext(Context)
    const [showLessons, setShowLessons] = useState(false)
    const [chapter, setChapter] = useState({});
    const [chapters, setChapters] = useState([]);
    const [chapterNum, setChapterNum] = useState(0);
    const [doneChapters, setDoneChapters] = useState([])
    const [doneLessons, setDoneLessons] = useState([])

    const params = useParams();
    const current = params.id
    const navigate = useNavigate()

    useEffect(() => {
        getCourse()
        getModules()
        getLesson(current)
        getChapters(current)
        SetCurrentLesson()
        getDoneChapters()
    }, [current])

    useEffect(() => {
        SetCurrentLesson()
    }, [chapter])


    async function SetCurrentLesson() {
        try {
            CoursesService.setCurrentLesson(store.user.id, courseStore.course.id, current)
        } catch (e) {
            console.log(e)
        }
    }

    async function getChapters(id) {
        try {
            const response = await CoursesService.fetchChapters(id);
            const dataArray = response.data.chapters; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setChapters(dataArray);
                setChapter(dataArray[0])
                localStorage.setItem("chapterId", dataArray[0].id);
                setChapterNum(0)
            } else {
                console.error(
                    "Ожидался массив, но получен другой тип данных:",
                    dataArray
                );
                setChapters([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function getLesson(id) {
        try {
            await courseStore.fetchLesson(id);
        } catch (e) {
            console.log(e);
        }
    }

    async function getCourse() {
        try {
            const response = await CoursesService.fetchUserCourse(current)
            if (response.data.course.course_content) {
                const parsedConent = JSON.parse(response.data.course.course_content)
                setContentList(parsedConent)
            }
            if (response.data.course.additional_type) {
                setTypes([...response.data.course.additional_type])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const chapterHandler = (item, index) => {
        setChapter(item);
        localStorage.setItem("chapterId", item.id);
        setChapterNum(index)
    };


    async function getModules() {
        try {
            const response = await CoursesService.fetchCourseModules(courseStore.course.id)
            const dataArray = response.data.modules; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setModules(dataArray);
                response.data.modules.map((item, index) => {
                    getLessons(item.id)
                })

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setModules([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function getLessons(id) {
        try {
            const response = await CoursesService.fetchModuleLessons(id)
            const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setLessons(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setLessons([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getDoneChapters = async () => {
        try {
            const response = await CoursesService.getAllDoneChapters(store.user.id, current)
            const chaptersData = response.data.allDoneChapters
            if (Array.isArray(chaptersData)) {
                setDoneChapters(chaptersData)
            } else {
                setDoneChapters([])
            }
        } catch (e) {

        }
    }

    const navigateHandler = (id) => {
        navigate(COURSE_CARD + '/' + courseStore.course.id + LEARN + LESSON_PAGE + `/${id}`)
    }

    const getDoneLessons = async () => {
        try {
            const response = await CoursesService.getAllDoneLessons(store.user.id, courseStore.course.id)
            const dataArray = response.data.allDoneLessons
            if (Array.isArray(dataArray)) {
                setDoneLessons(dataArray)
            } else {
                setDoneLessons([])
            }
        } catch (e) {
            console.log(e)
        }
    }


    const RenderArea = (i) => {
        const [chapterDescription, setChapterDescription] = useState([])
        const [answer, setAnswer] = useState('')
        const [video, setVideo] = useState('')
        const StyledReactQuill = styled(ReactQuill)`.ql-container { border: none;}`;
        const checkAnswerHandler = () => {
            if (answer == chapterDescription[0].answer) {
                CoursesService.setDoneChapter(store.user.id, courseStore.course.id, current, i.id)
            }
            getDoneChapters()
        }

        function isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        function getChapter() {
            if (!isJsonString(i.content)) {
                setChapterDescription([]);
            } else {
                const parsedContent = JSON.parse(i.content);
                setChapterDescription([parsedContent]);
            }
            setVideo(courseStore.chapter.video)
        }

        useEffect(() => {
            getChapter();
        }, [chapter]);

        useEffect(() => {
            if (chapterDescription.length !== 0) {
                setDoneChapter()
            }
        }, [chapterDescription])

        const modules = {
            toolbar: false
        }


        const setDoneChapter = async () => {
            if (chapterDescription[0].tag === 'test' || chapterDescription[0].tag === 'testMulti') {
            } else {
                await CoursesService.setDoneChapter(store.user.id, courseStore.course.id, current, i.id)
            }
            getDoneChapters()
            setDoneLesson()
            getDoneLessons()
        }

        const setDoneLesson = async () => {
            await CoursesService.setDoneLesson(store.user.id, courseStore.course.id, current)
        }

        const nextLessonHandler = () => {
            setChapterNum((prevIndex) => {
                const newIndex = (prevIndex + 1);
                if (newIndex > chapters.length - 1) {
                }
                else {
                    setChapter(chapters[newIndex]);
                    localStorage.setItem("chapterId", chapters[newIndex].id);
                    return newIndex;
                }

            });
        };


        return (
            <>
                {
                    chapterDescription.map((item, index) => (
                        <>

                            {
                                item.tag === "text" && (
                                    <>
                                        <div className={styles.opisanie}>
                                            <StyledReactQuill
                                                theme="snow"
                                                value={item.description}
                                                readOnly={true}
                                                modules={modules}
                                            />

                                        </div>
                                    </>
                                )
                            }
                            {
                                item.tag === "test" && (
                                    <>
                                        <div className={styles.opisanie}>
                                            <StyledReactQuill
                                                theme="snow"
                                                value={item.name}
                                                readOnly={true}
                                                modules={modules}
                                            />
                                        </div>
                                        <div className={styles.test}>
                                            <p>Выберите подходящий ответ</p>
                                            {
                                                item.description.map((item, index) => (
                                                    <>
                                                        <div key={index}>
                                                            <div className={styles.testPoints}>
                                                                <input
                                                                    onChange={() => setAnswer(item.number)}
                                                                    type='radio'
                                                                    id={`option-${item.number}`}
                                                                    name="option"
                                                                    value={i.number}
                                                                    // checked={i.number == chapterDescription.answer}
                                                                    className={styles.radioBtn}
                                                                />
                                                                <label htmlFor={`option-${item.number}`}>
                                                                    {
                                                                        item.description
                                                                    }
                                                                </label>

                                                            </div>

                                                        </div>
                                                    </>
                                                ))
                                            }
                                            <button className={styles.checkAnswers} onClick={checkAnswerHandler}>
                                                <img src={checkTest} alt="" />
                                                {
                                                    !doneChapters.some(doneChapter => doneChapter.chapter_id == i.id) &&
                                                    <>
                                                        Проверить ответ
                                                    </>
                                                }

                                                {
                                                    doneChapters.some(doneChapter => doneChapter.chapter_id == i.id) &&
                                                    <>
                                                        Тест решен
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </>
                                )
                            }
                            {
                                item.tag === "testMulti" && (
                                    <>
                                        <div className={styles.opisanie}>
                                            <StyledReactQuill
                                                theme="snow"
                                                value={item.name}
                                                readOnly={true}
                                                modules={modules}
                                            />
                                        </div>
                                        <div className={styles.test}>
                                            <p>Выберите подходящие ответы</p>
                                            {

                                                item.description.map((item, index) => (
                                                    <>
                                                        <div key={index}>
                                                            <input
                                                                onChange={() => setAnswer(item.number)}
                                                                type='checkbox'
                                                                id={`option-${item.number}`}
                                                                name="option"
                                                                value={item.number}
                                                                // checked={testContent.answer.includes(i.number.toString())}
                                                                className={styles.checkBox}

                                                            />

                                                            <label htmlFor={`option-${item.number}`}>
                                                                {
                                                                    item.description
                                                                }
                                                            </label>

                                                        </div>
                                                    </>
                                                ))
                                            }

                                            <button className={styles.checkAnswers}>
                                                <img src={checkTest} alt="" />
                                                {
                                                    !doneChapters.some(doneChapter => doneChapter.chapter_id == i.id) &&
                                                    <>
                                                        Проверить ответы
                                                    </>
                                                }

                                                {
                                                    doneChapters.some(doneChapter => doneChapter.chapter_id == i.id) &&
                                                    <>
                                                        Тест решен
                                                    </>
                                                }
                                            </button>
                                        </div>
                                    </>
                                )
                            }
                            {
                                item.tag === "codeEditor" && (
                                    <>
                                        <div className={styles.opisanie}>
                                            <StyledReactQuill
                                                theme="snow"
                                                value={item.name}
                                                readOnly={true}
                                                modules={modules}
                                            />
                                        </div>
                                        <CodeMirror
                                            value={item.description}
                                            theme={aura}
                                            width="800px"
                                            height='500px'
                                            extensions={[langs.javascript(), EditorView.editable.of(false)]}
                                            placeholder={'// Напишите шаблон программы'}
                                            basicSetup={{
                                                highlightActiveLineGutter: true,
                                                highlightSpecialChars: true,
                                                history: true,
                                                drawSelection: true,
                                                dropCursor: true,
                                                allowMultipleSelections: true,
                                                indentOnInput: true,
                                                syntaxHighlighting: true,
                                                bracketMatching: true,
                                                closeBrackets: true,
                                                autocompletion: false,
                                                rectangularSelection: true,
                                                crosshairCursor: true,
                                                highlightActiveLine: true,
                                                highlightSelectionMatches: true,
                                                closeBracketsKeymap: true,
                                                defaultKeymap: true,
                                                searchKeymap: true,
                                                tabSize: 4,
                                                readOnly: true
                                            }}
                                        />
                                    </>
                                )
                            }

                            {
                                item.tag === "video" && (
                                    <>
                                        <div className={StyleSheet.videoField}>
                                            <video width="820" height="400" controls
                                                src={process.env.REACT_APP_API_URL + `${video}`}></video>
                                        </div>
                                        <div className={styles.opisanie}>
                                            <StyledReactQuill
                                                theme="snow"
                                                value={item.description}
                                                readOnly={true}
                                                modules={modules}
                                            />
                                        </div>
                                    </>
                                )
                            }

                        </>

                    ))

                }
                {
                    i.number < chapters.length &&
                    <button
                        className={styles.nextButton}
                        onClick={() => nextLessonHandler()

                        }>
                        Далее
                    </button>
                }
            </>
        );
    };



    return (
        <>
            <DocumentTitle title={courseStore.lesson.name}>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={`${styles.content}`}>
                        <BurgerMenu />

                        <div className={styles.lesson}>
                            <div className={styles.lessonContent}>
                                <div className={styles.zagolovok}>
                                    <div className={styles.backButton} onClick={() => navigate(LEARN_MAIN_PAGE + '/' + courseStore.course.id)}>
                                        <img src={arrowLeft} alt="" />
                                    </div>
                                    <p>{courseStore.lesson.name}</p>
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.chaptersBar}>
                                        {
                                            chapters.length !== 0 &&
                                            <>
                                                {
                                                    chapters.map((item, index) => (
                                                        <>

                                                            <button
                                                                className={`${localStorage.getItem("chapterId") == item.id ? styles.selectedChapter : styles.chapter} ${doneChapters.some(doneChapter => doneChapter.chapter_id == item.id) ? styles.doneChapter : styles.chapter}`}
                                                                key={index}
                                                                onClick={() => chapterHandler(item, index)}
                                                            >
                                                                {index + 1}
                                                                {
                                                                    localStorage.getItem("chapterId") == item.id &&
                                                                    <p>раздел</p>
                                                                }
                                                            </button>
                                                        </>
                                                    ))
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className={styles.mainLessonInfo}>
                                        {
                                            RenderArea(chapter)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.navPanel}>
                                <p>Содержание</p>
                                <div className={styles.modulesAndLessons}>
                                    {
                                        modules.map((item, index) => (
                                            <>
                                                <div className={styles.module} key={index}>
                                                    <p>
                                                        {item.name}
                                                    </p>
                                                    <div className={styles.lessons}>
                                                        <ListLessons id={item.id} navigateCB={navigateHandler} doneLessons={doneLessons} />
                                                    </div>
                                                </div>
                                            </>
                                        ))

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </DocumentTitle>
        </>
    )
}


function ListLessons({ id, navigateCB, doneLessons }) {

    const params = useParams();
    const current = params.id
    const [lessons, setLessons] = useState([])
    useEffect(() => {
        getLessons(id)
    }, [id])

    const navigateHandler = (id) => {
        navigateCB(id)
    }

    async function getLessons(id) {
        try {
            const response = await CoursesService.fetchModuleLessons(id)
            const dataArray = response.data.lessons; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setLessons(dataArray);

            } else {
                console.error('Ожидался массив, но получен другой тип данных:', dataArray);
                setLessons([]); // Установка пустого массива в случае ошибки
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            {
                lessons.map((item, index) => (
                    <>
                        <div className={`${current == item.id ? styles.currentLesonBlock : styles.lessonBlock} ${doneLessons.some(i => i.lesson_id === item.id) ? styles.doneLesson : styles.lessonBlock}`} onClick={() => navigateHandler(item.id)}>
                            <p>
                                {index + 1 + '. '}
                                {item.name}

                            </p>
                            {
                                doneLessons.some(i => i.lesson_id === item.id) &&
                                <>
                                    <img src={galochka} alt="" />
                                </>
                            }
                        </div>
                    </>
                ))
            }
        </>
    )
}

export default observer(CoursesContentPage)