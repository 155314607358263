import React, { useContext } from "react";
import styles from '../styles/footer.module.css'
import { Context } from "..";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { AUTHORIZATION_PAGE, CATALOG_ROUTE, CREATE_ROUTE, LOGIN_PAGE, POLICY_PAGE, SETTINGS_ROUTE } from "../utils";
import mailIcon from '../Images/mailIcon.svg'
function Footer() {
    const { store } = useContext(Context)
    const navigate = useNavigate()

    const navigateHnadler = (path) => {
        if (path === 'policy') {
            navigate(POLICY_PAGE)
        }
        if (path === 'catalog') {
            navigate(CATALOG_ROUTE)
        }
        if (path === 'create') {
            if (store.isAuth) {
                navigate(SETTINGS_ROUTE + '/' + store.user.id, { state: { from: '/burgerMenu' } })
            } else {
                navigate(LOGIN_PAGE)
            }
        }
        if (path === 'login') {
            navigate(AUTHORIZATION_PAGE)
        }
        if (path === 'signin') {
            navigate(AUTHORIZATION_PAGE)
        }
    }

    return (
        <>
            <footer>
                <div className={styles.container}>
                    <div className={styles.leftBlock}>
                        <div className={styles.firstBlock}>
                            <button className={styles.logo}>
                                Theta
                            </button>
                            <div className={styles.description}>
                                Обучайтесь, делитесь опытом и прокачивайте свои скилы
                            </div>
                            <p className={styles.contactUs}><img src={mailIcon} alt="" />support@theta-learn.ru</p>
                        </div>
                        <div className={styles.secondBlock}>
                            <p>Платформа</p>
                            <button onClick={() => navigateHnadler('catalog')}>Обучаться</button>
                            <button onClick={() => navigateHnadler('create')}>Преподавать</button>
                            {/* <button>Тренироваться</button> */}
                        </div>
                        {/* <div className={styles.secondBlock}>
                            <p>Правовая информация</p>
                            <button>Политика конфиденциальности</button>
                        </div> */}
                    </div>
                </div>
            </footer>
        </>)

}

export default observer(Footer)