import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import { observer } from "mobx-react-lite";
import Footer from "../components/Footer";
import styles from "../styles/catalog.module.css"
import { Context } from "..";
import { COURSE_CARD, COURSE_PAGE, CREATE_ROUTE, TEST_PAGE } from "../utils";
import { useNavigate } from "react-router-dom";
import DocumentTitle from "react-document-title";
import CoursesService from "../service/CoursesService";
import heart from '../Images/heart.svg'
import MainCoursePage from "./MainCoursePage";
import { toast } from "react-toastify";
import redHeart from '../Images/redHeart.svg'
import BurgerMenu from "../components/BurgerMenu";

function Catalog() {
    const { store, courseStore } = useContext(Context)
    const navigate = useNavigate()
    const [courses, setCourses] = useState([])
    const [favoriteCourses, setFavoriteCourses] = useState([])

    useEffect(() => {
        GetFavoriteCourses()
    }, [])


    async function GetFavoriteCourses() {
        try {
            const response = await CoursesService.fetchAllFavorites(localStorage.getItem('userId'))
            const coursesArray = response.data.courses
            if (Array.isArray(coursesArray)) {
                setFavoriteCourses(coursesArray)
            }
            else {
                setFavoriteCourses([])
            }
        } catch (e) {
            console.log(e)
        }
    }


    const navigateHandler = (path, id) => {
        if (path === 'create') {
            navigate(CREATE_ROUTE)
        }

        if (path === 'course') {
            navigate(COURSE_CARD + `/${id}`)
        }
    }

    useEffect(() => {
        getCourses()
    }, [])


    async function getCourses() {
        try {
            const response = await CoursesService.fetchPublishedCourses()
            const dataArray = response.data.courses; // Предполагается, что courses - это массив в модели
            if (Array.isArray(dataArray)) {
                setCourses(dataArray);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const addToFavoriteHandler = async (id) => {
        try {
            const response = await CoursesService.addToFavorite(id, store.user.id)
            toast.success('Успех', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            console.log(e)
        }
        GetFavoriteCourses()
    }


    return (
        <DocumentTitle title="Все курсы">
            <>

                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={`${styles.content} `}>
                        <BurgerMenu />

                        <div className={styles.courses}>
                            <p>Каталог</p>
                            {
                                courses.length !== 0 &&
                                courses.map((item, index) => (
                                    <>

                                        <div key={index} className={styles.course}>
                                            <div className={styles.img}>
                                                <img src={process.env.REACT_APP_API_URL + index.img} alt="" />
                                            </div>
                                            <div className={styles.info}>
                                                <p>{item.name}</p>
                                                <div className={styles.description}>
                                                    <p>{item.description}</p>
                                                </div>
                                                <div>
                                                    <p className={styles.price}>{item.price === '0' ? 'Бесплатно' : item.price}</p>
                                                </div>
                                            </div>
                                            <div className={styles.more}>
                                                <button className={styles.addToFavorite} onClick={() => addToFavoriteHandler(item.id)}>
                                                    {
                                                        !favoriteCourses.some(i => i.id === item.id) &&
                                                        < img src={heart} alt="" />
                                                    }

                                                    {
                                                        favoriteCourses.some(i => i.id === item.id) &&
                                                        < img src={redHeart} alt="" />
                                                    }
                                                </button>
                                                <button className={styles.podrobnee} onClick={() => navigateHandler('course', item.id)}>
                                                    Подробнее
                                                </button>

                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}

export default observer(Catalog)